%hoverEffect{
    background-position: center bottom;
}

%bulletList{
    background: url(../images/bullet.jpg) 6px center no-repeat;
    padding-left: 18px;
    float: left;
}

%clearfix{
    &::after {
        content: "";
        clear: both;
        display: table;
    }
}