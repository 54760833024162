
.popup_overlay {
    display: none;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index:1001;
    -moz-opacity: 0.7;
    opacity:.70;
    filter: alpha(opacity=70);
    cursor: pointer;
}

.popup_content {
    display: none;
    position: fixed;
    top: 35%;
    left: 25%;
    // width: 50%;
    // height: 50%;
    background-color: white;
    z-index:1002;
    // overflow: auto;
    position: fixed;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 837px;
}


.popup_close {
	position: absolute;
    right: -13px;
    text-align: center;
    top: -13px;
    width: 30px;
    height: 30px;
	text-decoration: none;
	font-weight: bold;
    color: white;
    background: url(../images/index/close_btn.png);
    z-index: 1;
    cursor: pointer;
}


.platform-login{background:url(../images/platform/bg7_2.jpg) left center repeat-x;  height:210px; position:relative;border: 1px solid #b1a174;}
.platform-login a{background:url(../images/index/pop-btn.png) no-repeat; display:block; width:91px; height:45px; position:absolute; text-indent:-9999px; cursor:pointer;}
.platform-login a:hover{background-position:left bottom !important;}
.platform-login a.platform1{top:105px; left:12px;}
.platform-login a.platform2{top:155px; left:117px;}
.platform-login a.platform3{top:105px; left:220px;}
.platform-login a.platform4{top:155px; left:340px;}
.platform-login a.platform5{top:95px; left:455px;}
.platform-login a.platform6{top:157px; left:583px;}
.platform-login a.platform7{top: 95px;left: 718px;}

.platform-login{
    .platformHead{
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        padding: 12px;
        background-color: #101010;
        h3{
            background: -webkit-linear-gradient(#f6d391 50%, #b99847 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        
    }
    .platformLogo{
        width: 126px;
        position: absolute;
        

        img{
            width: 100%;
        }
        

        &.sunbetLogo{
            left: -7px;
            top: 44px;
        }

        &.allbet9Logo{
            left: 90px;
            top: 96px;
            width: 140px;
        }

        &.allbetLogo{
            left: 218px;
            top: 40px;
        }

        &.vsLogo{
            left: 330px;
            top: 97px;
        }

        &.agLogo{
            left: 447px;
            top: 36px;
        }

        &.kenoLogo{
            left: 572px;
            top: 96px;
        }

        &.n2liveLogo{
            left: 696px;
            top: 36px;
        }


    }

}

