.page-content{
	&.inner-page{
		padding-top:0.5%;
	}
}

/** common **/
p.errorMsg{
	color:#ffa2a2 !important;
	font-weight: bold;
}

.sucessDetail{
	p{
		color:#FFF !important;
	}
}
.cny8888-table{
	width:100%;
	background: #1f090e;

	th{
		font-size: 14px;
		font-weight: bold;
		color:#FFF;
	}

	th,td{
		padding:10px;
		text-align: center;
		font-size: 15px;
    	font-weight: bold;
	}

	tr{
		position: relative;
		&:nth-child(even){
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a90329+0,5d333a+24,5d333a+76,6d0019+100&0+1,1+25,1+75,0+100 */
			background: -moz-linear-gradient(left, rgba(169,3,41,0) 0%, rgba(166,5,42,0) 1%, rgba(93,51,58,0.96) 24%, rgba(93,51,58,1) 25%, rgba(93,51,58,1) 75%, rgba(93,51,58,0.96) 76%, rgba(109,0,25,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(169,3,41,0) 0%,rgba(166,5,42,0) 1%,rgba(93,51,58,0.96) 24%,rgba(93,51,58,1) 25%,rgba(93,51,58,1) 75%,rgba(93,51,58,0.96) 76%,rgba(109,0,25,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(169,3,41,0) 0%,rgba(166,5,42,0) 1%,rgba(93,51,58,0.96) 24%,rgba(93,51,58,1) 25%,rgba(93,51,58,1) 75%,rgba(93,51,58,0.96) 76%,rgba(109,0,25,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a90329', endColorstr='#006d0019',GradientType=1 ); /* IE6-9 */
					
			@media(max-width:480px){
				background-attachment:fixed;
			}

		}
	}
}

.cny8888-ul{
	list-style: initial;
	padding-left: 15px;
	
	li{
		color: #f0e893;
		font-size: 14px;
		line-height: 20px;
		padding: 5px 0px;
	}
}

.cny8888-ol{
	list-style: decimal;
	padding-left: 15px;
	
	li{
		color: #f0e893;
		font-size: 14px;
		line-height: 20px;
		padding: 5px 0px;
	}
}

.clearfix{
	clear:both;
}

.img-responsive{
	width:100%;
}

.common-left-wrapper{
	width:20%;
	float:left;
}

.common-right-wrapper{
	width: 77.8%;
    float: left;
    margin-left: 2%;
}

.common-red-bg{
    border: 1px solid #7d454b;
    height: auto !important;
    background: url('../images/inner_page/inner_content_bg.jpg') no-repeat top center #1d0b0e;

    &.none{
    	background:#1d0b0e;
    }

    p{
    	color:#FFF;
    	margin-top: 10px;
    	font-size:13px;
    	line-height: 1.5;
    }
}

.common-text-wrapper-padding{
    padding: 5% 5%;
    a{
    	color:#cec66e;
    	&:hover{
    		text-decoration: underline;
    	}
    }

    &.nopadding{
    	padding:0px;
    }
}

.common-title-wrapper{
    padding-bottom: 10px;
    margin-bottom: 10px;
	border-bottom: 1px solid #5f202d;
	&.no-border{
		border:none;
	}

	.full-width{
		width:100%;
	}

	&.news{
		padding-bottom: 0px;
		margin-bottom: 0px;
		border-bottom: none;

		.securities {
            background-image: url(../images/news/bg1.jpg);
            height: 102px;
		}

		.sports {
            background-image: url(../images/news/bg2.jpg);
            height: 102px;
		}
		
		.finance {
            background-image: url(../images/news/bg3.jpg);
            height: 102px;
		}
		
		.entertainment {
            background-image: url(../images/news/bg4.jpg);
            height: 102px;
        }
		

	}
}

/** recharge flow **/
.flow-list-subtitle{
	margin-top: 13px;
    color: #feffa8;
    font-size: 15px;
	font-weight: bold;
}

.flow-list{
	color:#FFF;
	margin-top: 2%;
	list-style-type:decimal;
	padding-left:3.5%;
	li{
		line-height: 20px;
		margin-bottom: 1.5%;
		font-size:13px;
		line-height: 1.5;
	}
}

.flow-point-wrapper{
    margin-bottom: 6%;
}


/** promotion main **/
.common-promotion-inner-content{
	padding:0% 6%;
	p{
		color:#f0e893;
		font-size:14px;
		line-height: 1.5;
		line-height: 20px;
	    margin-top: 8px;
	    margin-bottom: 15px;
	}

	h3{
	    color: #fffde3;
	    padding-bottom: 6px;
	    border-bottom: 2px solid #958f5d;
	    font-size:14px;
	    font-weight: bold;
	}

	.promotion-remark-box{
		p{
			color:#d4d1aa;
		}
	    padding: 1% 3%;
		border:2px solid #644239;
		border-radius: 10px;
		background: rgb(24,14,15);
		background: -moz-linear-gradient(top, rgba(24,14,15,1) 0%, rgba(44,28,28,1) 100%);
		background: -webkit-linear-gradient(top, rgba(24,14,15,1) 0%,rgba(44,28,28,1) 100%);
		background: linear-gradient(to bottom, rgba(24,14,15,1) 0%,rgba(44,28,28,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#180e0f', endColorstr='#2c1c1c',GradientType=0 );
	}
}

.promotion-rows{
	background:url('../images/inner_page/promotion/each-box.jpg') center center no-repeat;
	background-size: cover;
    padding: 20px 10px;
    margin-bottom: 4%;

    .promotion-img{
    	img{
    		width:100%;
    	}

    	width:35%;
    	float:left;
    }

    .promotion-desc-wrapper{
	    width: 64%;
    	float: left;
	    padding: 0px 0px 0px 20px;
   		box-sizing: border-box;
    }
}

.promotion-title{
	color: #feffa8;
    padding-bottom: 5px;
    font-weight: bold;
	border-bottom: 1px solid rgba(86, 84, 84, 0.5);
	line-height: 1.2;
	font-size:18px;
	@media(max-width:480px){
		font-size:18px;
	}
}

.promotion-desc{
	ul{
		list-style: url('../images/inner_page/promotion/bullet.jpg');
		padding-left: 3%;
		li{
			padding: 8px 0px 5px 0px;
			color:#FFF;
			line-height: 20px;
			border-bottom: 1px dashed rgba(86, 84, 84, 0.5);
			font-size:13px;
			@media(max-width:480px){
				font-size:13px;
			}
		}
	}
}

.promotion-more-btn{
	background:url('../images/inner_page/promotion/more_btn_s.png') top left no-repeat;
	background-size: contain;
	text-align: center;
	// background:url('../images/inner_page/promotion/btn.jpg') top left no-repeat;

    width: 81px !important;
    height: 28px !important;
    color: transparent;
    display: inline-block;
    text-decoration: none;
	float: right;
	// text-indent: -99999px;
	margin-top: 10px;
    line-height: 22px;
    color: #FFF !important;
	
	@media(max-width:480px){
		background:url('../images/inner_page/promotion/more_btn.png') top left no-repeat;
		width: 173px !important;
		height: 40px !important;
		text-align: center;
		color:#FFF !important;
		float: none;
		text-indent: 0px;
		line-height: 36px;
		font-size: 16px;
		font-weight: bold;

		&:hover{
			background:url('../images/inner_page/promotion/more_btn.png') top left no-repeat;
			color:#cec66e !important;
		}
	}

    &:hover{
    	// background:url('../images/inner_page/promotion/btn.jpg') bottom left no-repeat;
    	background:url('../images/inner_page/promotion/more_btn_s_hover.png') top left no-repeat;
    	background-size: contain;
    	color:#FFF !important;
    	text-decoration: none !important;
    }
}

.promotion-more-btn-wrapper{
	@media(max-width:480px){
		text-align: center;
	}
}

.show-in-mobile{
	display:none;
	@media(max-width:480px){
		display:block;
	}
}

.select-type-input{
	width: 21%;
	display:inline-block;
	margin-bottom: 5px;

	input[type="radio"]{
		margin: 0px;
		vertical-align: text-bottom;
		margin-right: 2px;	
	}

	@media(max-width:930px){
		width: 33%;
		float: left;
	}

	@media(max-width:320px){
		width: 40%;
	}
}

.radio-margin{
	.select-type-input{
		margin-bottom: 15px;	
	}
}


/** agent **/
.agent-logo-wrapper{
	margin-bottom: 2%;
	text-align: center;

	@media(max-width:800px){
		margin-bottom: 7%;
	}

	.agent-logo{
		width: 24%;
		text-align: center;
		display: inline-block;
		vertical-align: middle;
		box-sizing: border-box;
		img{
			max-width: 100%;
		}

		@media(max-width:800px){
			width: 48%;
			padding: 5px;
		}
	}
}


#agent-whole-wrapper{
    border: #9c3e4d 1px solid;
	// background:url('../images/inner_page/agent/agent_image1i.jpg') no-repeat #141112;  
	background:url('../images/inner_page/agent/pink_bg.jpg') no-repeat #141112;  
	padding-top: 35px;

	@media(max-width:800px){
		padding-top: 5px;
	}

	#agent-content-wrapper{
		width:85%;
		margin:auto;
		.order-list{
			padding-left: 4%;
			li{
				margin-bottom: 3%;
				font-size:13px;
				line-height: 1.5;
			}
		}
	}  
}

#agent-title-bg{
	background:url('../images/inner_page/agent/agent_banner_btn.png') center center no-repeat;
	text-align: center;
	padding: 10px;
	color:#faff06;
	font-size: 14px;
    letter-spacing: 1px;
	margin-bottom: 4%;
}

.flower-line-wrapper{
	position: relative;
}

.flower-line{
	position: absolute;
	background:url('../images/inner_page/agent/flower_line.png') top center no-repeat;
	height: 168px;
	width:100%;
	top: -154px;

	@media(max-width:480px){
		height: 168px;
		width: 122%;
		left: -10%;
		top: -148px;
	}

	@media(max-width:320px){
		left: -13%;
	}
}

.agent-box-border{
	position: relative;
    border: 2px solid #630a26;
    border-radius: 5px;
    padding: 2%;
    position: relative;
    margin-top: 6%;
    background: rgb(43,43,43);
background: -moz-linear-gradient(top, rgba(43,43,43,1) 0%, rgba(44,44,44,1) 12%, rgba(17,17,17,1) 49%, rgba(28,28,28,1) 91%, rgba(19,19,19,1) 100%);
background: -webkit-linear-gradient(top, rgba(43,43,43,1) 0%,rgba(44,44,44,1) 12%,rgba(17,17,17,1) 49%,rgba(28,28,28,1) 91%,rgba(19,19,19,1) 100%);
background: linear-gradient(to bottom, rgba(43,43,43,1) 0%,rgba(44,44,44,1) 12%,rgba(17,17,17,1) 49%,rgba(28,28,28,1) 91%,rgba(19,19,19,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b2b2b', endColorstr='#131313',GradientType=0 );

    .agent-box-desc{
    	color:#fcf9dd;
    }

    .agent-box-title{
    	color:#ccc6c6;
    	font-size:15px;
    	padding-bottom: 3px;
    	border-bottom: 1px solid #aaa;
    	margin-bottom: 7px;
    	font-weight: bold;
    }

    .agent-deco-top{
    	display:none;
    }
}

.order-list{
	list-style: decimal;
	li{
		color:#FFF;
		line-height: 20px;
		ol{
			padding-left: 4%;
   			list-style: decimal;
   			margin-top: 3%;
		}
	}

	&.terms{
		padding-left: 3%;
		@media(max-width:480px){
			padding-left: 6%;
		}
	}
}

.agent_apply{
    // background: url(../images/inner_page/agent/agent_apply.jpg) no-repeat;
    // width: 151px;
    // height: 39px;
    // text-indent: -9999px;
	background: url(../images/form/submit_btn.png) center center no-repeat transparent;

	background-size: contain !important;
    text-align: center;
    height:43px;
    width:125px;
    color:#ffe5b4;
    line-height: 47px;
	text-shadow: 0px 0px 4px #ffeb3b;

    margin: 20px auto 20px;
    display: block;
    &:hover{
    	// background-position: left bottom !important;
    	background: url(../images/form/submit_btn_hover.png) center center no-repeat transparent;
    	text-decoration: none !important;
	}
	
	@media(max-width:480px){
		background: url(../images/form/submit_btn.png) center center no-repeat transparent;
		background-size: contain !important;
		height: 71px;
		width: 202px;
		text-indent: 0px;
		text-align: center;
		color: #ffe5b4 !important;
		text-shadow: 0px 0px 4px #ffeb3b;
		line-height: 78px;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 1px;
	}
}

/** games intro **/
.game_detail_img{
	text-align: center;
	margin-bottom: 2%;
	img{
		@media(max-width:480px){
			width:100%;
		}
	}
}

.game_detail_title{
	font-size:18px;
	color:#feffa8;
	font-weight: bold;
	margin-bottom: 2%;
}

.game_detail{
	.order-list{
		padding-left: 3%;
		@media(max-width:480px){
			padding-left: 6%;
		}
	}

	.circle-list{
		color:#FFF;
		line-height: 20px;
		padding-left: 3%;
		font-size:14px;
		line-height: 1.5;
		list-style: initial;
		@media(max-width:480px){
			padding-left: 6%;
		}
	}

	p{
		margin-bottom: 2%;
	}

	h4{
		color:#feffa8;
		font-size:14px;
	}

	table{
	    border: 1px solid #000;
	    border-collapse: collapse;
	    margin-top: 15px;
	    width:100%;

		thead{
			th{
			    background: rgb(157,3,3);
				background: -moz-linear-gradient(top, rgba(157,3,3,1) 0%, rgba(49,1,1,1) 100%);
				background: -webkit-linear-gradient(top, rgba(157,3,3,1) 0%,rgba(49,1,1,1) 100%);
				background: linear-gradient(to bottom, rgba(157,3,3,1) 0%,rgba(49,1,1,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9d0303', endColorstr='#310101',GradientType=0 );
	    		color: #fff;
			}
		}
		th{
	        background: #310101;
    		color: #fff;
		    line-height: 22px;
    		padding: 0 10px;
		}

		td{
		    text-align: center;
		    border-left: 1px solid #310101;
		    border-right: 1px solid #310101;
	        line-height: 22px;
    		padding: 2px 10px;
    		background:#FFF;
			color:#000;
		}

		tr{
			&.even{
				td{
				    background: #FFDDDC;
				}
			}
		}
	}
}

.common-gaming-tag-wrapper{
	margin-bottom: 3%;
	text-align: right;
	.gaming-tabs{
        padding: 5px 20px;
	    margin-right: 5px;
	    cursor: pointer;
        display: inline-block;
    	border: #dbe1e6 2px solid;
    	background: rgb(29,29,29);
		background: -moz-linear-gradient(top, rgba(29,29,29,1) 0%, rgba(29,29,29,1) 51%, rgba(29,29,29,1) 100%);
		background: -webkit-linear-gradient(top, rgba(29,29,29,1) 0%,rgba(29,29,29,1) 51%,rgba(29,29,29,1) 100%);
		background: linear-gradient(to bottom, rgba(29,29,29,1) 0%,rgba(29,29,29,1) 51%,rgba(29,29,29,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d1d1d', endColorstr='#1d1d1d',GradientType=0 );

    	&.active{
		    border: #d6b80c 2px solid;
		    background: rgb(92,11,5);
			background: -moz-linear-gradient(top, rgba(92,11,5,1) 0%, rgba(75,5,2,1) 50%, rgba(62,2,0,1) 100%);
			background: -webkit-linear-gradient(top, rgba(92,11,5,1) 0%,rgba(75,5,2,1) 50%,rgba(62,2,0,1) 100%);
			background: linear-gradient(to bottom, rgba(92,11,5,1) 0%,rgba(75,5,2,1) 50%,rgba(62,2,0,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5c0b05', endColorstr='#3e0200',GradientType=0 );
		    a{
		    	color:#FFF;
		    }
    	}

    	a{
		    color: #cec66e;
		    outline: none;
		    &:hover{
		    	text-decoration: underline;
		    }
    	}

    	@media(max-width:480px){
    		width:18%;
		    padding: 5px 1%;
    	}
	}
}

.common-games-box{
	width:50%;
    float: left;
	box-sizing:border-box;
	padding:5px;
	.games-box-title{
		font-weight: bold;
	    font-size: 14px;
	    line-height: 24px;
	    color: #fff;
	    padding-left: 10px;
	    background: rgb(116,2,2);
		background: -moz-linear-gradient(top, rgba(116,2,2,1) 1%, rgba(196,48,48,1) 100%);
		background: -webkit-linear-gradient(top, rgba(116,2,2,1) 1%,rgba(196,48,48,1) 100%);
		background: linear-gradient(to bottom, rgba(116,2,2,1) 1%,rgba(196,48,48,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#740202', endColorstr='#c43030',GradientType=0 );
	}

	.games-box-desc-wrapper{
	    border: 1px solid #825A5F;
	    padding: 3%;
	    line-height: 20px;
	    color:#FFF;
	    .games-box-desc{
	    	width:49.5%;
	    	display:inline-block;
    	    float: left;
	        min-height: 150px;
			line-height: 15px;
			text-align: justify;
			padding-right: 5px;
			box-sizing: border-box;
	    }

	    .games-box-img{
	    	width:49%;
	    	display:inline-block;
		    float: right;
		    text-align: right;
		    img{
		    	width:100%;
		    }
	    }
	}
}

.common-gaming-content{
	img{
		width:100%;
	}
}

#gameInnerPage {
	padding-bottom: 50px;
	hr {
		&.headerLine {
			display: block;
			margin: 0 auto;
		}
	}
	
	.gameWrapper {
		position: relative;
		max-width: 610px;
		margin: 0 auto;
	}
	
	.gameItm {
		padding: 0 20px 25px;
		border-bottom: 2px solid #e7ba65;
	}
	
	.themeImg {
		display: block;
		width: 100%;
	}
	
	.intro {
		margin: 20px 0;
	}
	
	.loginBtn {
		img {
			display: block;
			width: 87px;
			margin: 0 auto;
		}
	}

	p{
		line-height: 1.5;
		color: #fff;
	}

	.headerLine {
		max-width: 610px;
		margin: 0 auto;
		padding-bottom: 20px;
		
		&.noSpace {
			padding-bottom: 0;
		}
		
		img {
			width: 100%;
			display: block;
		}
	}
}

.rule-btn{
    background: url(../images/inner_page/games/flagship/sport/inner_content_bbsport_button.png) no-repeat;
    width: 120px;
    height: 39px;
    text-indent: -9999px;
    margin: 18px auto;
    cursor: pointer;
    display: block;
    &:hover{
	    background-position: bottom left !important;
    }
}

h4.common-h4{
    color: #feffa8;
    font-weight: bold;
    margin: 3% 0px;

}

h3.common-h3{
    color: #feffa8;
    font-weight: bold;
    margin: 3% 0px;
}

.common-flagship-box{
    padding: 5px;
    box-sizing: border-box;

	&.three{
		width:33.333%;
		display:inline-block;
		float:left;
	}

	&.four{
		width:25%;
		display:inline-block;
		float:left;
	}

	.common-flagship-border{
	    border: 2px solid #D6B80C;
	    display: inline-block;
	    img{
	    	float:left;
	    	width:100%;
	    }
	}
}

.vs-games{
	.common-flagship-box{
	    margin-bottom: 4%;
		.common-flagship-border{
			border:none;
		}
	}
}

.common-gaming-box-wrapper{
	&.tickets{
		.common-games-box{
			.games-box-desc-wrapper{
				.games-box-desc{
					min-height: 250px;
				}
			}
		}
	}
}

.common-gaming-text-wrapper{
	h3{
		color: #feffa8;
		font-weight: bold;
		font-size:1.17em;

	}

	p{
		color:#FFF;
	}

	&.vs-live{
		p{
			margin:13px 0px;
		}
	}
}

.original-width{
	max-width:100%;
}

.vs_live_background.table{
	width: 100%;
    border: 1px solid #D6B80C;
    border-collapse: collapse;
    margin-top: 25px;

    th{
	    background: rgb(92,11,5);
		background: -moz-linear-gradient(top, rgba(92,11,5,1) 1%, rgba(62,2,0,1) 100%);
		background: -webkit-linear-gradient(top, rgba(92,11,5,1) 1%,rgba(62,2,0,1) 100%);
		background: linear-gradient(to bottom, rgba(92,11,5,1) 1%,rgba(62,2,0,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5c0b05', endColorstr='#3e0200',GradientType=0 );
	    color: #fff;
	    border-bottom: 1px solid #D6B80C;
	    font-weight: bold;
	    line-height: 20px;
    }

    td{
	    font-size: 13px;
	    text-align: center;
	    color:#FFF;
		line-height: 20px;
		padding:3px 5px;
    }
}

.vs-live{
	.half-text{
		width:70%;
		float:left;
		display:inline-block;
		img{
			width:100%;
		}

		&:first-child{
			width:30%;
		}
	}
}

/** contact us **/
.inner_contactus_background{
	margin-top: 5%;
	background:url('../images/inner_page/contact_us/inner_contactus_bg.png') no-repeat;
	border:1px solid #874950;
    padding: 10% 3%;
	.content_table{
		width:65%;
	    margin: 0 0 0 40px;
	    td{
    	    text-align: left;
    		padding: 15px 0;
			vertical-align: middle;
			color: #f0e893;

	    	&.title{
	    		text-align: left;
    			color: #ce8f2b;
    			padding-right: 10px;
			    width: 150px;
			    vertical-align: middle;
	    	}

	    	&.underscore{
				border-bottom: 1px dashed #c1333d;
				a{
					color: #f0e893;
				}
	    	}
	    }

	    .invitation{
	    	    // background: url('../images/inner_page/contact_us/inner_contactus_button.gif') no-repeat;
			    text-align: left;
			    color:#FFF;
			    background: url('../images/inner_page/contact_us/24_hours.png') no-repeat;
			    width: 181px;
			    height: 54px;
			    background-size:contain;
			    // text-indent: -9999px;
			    line-height: 59px;
			    font-size: 16px;
			    padding-left: 13px;
			    font-weight: bold;
			    display: block;
			    color:#f9d388;
	    }
	}
}

/** platform **/
#innerPageTitle{
	font-size: 18px;
    font-weight: bold;
    color: #ffd872;
    text-align: center;
    padding: 10px;
}

.headerLine{
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;

    img{
    	width:100%;
    }
}

#platformInnerPage{
	padding:1% 0% 5% 0%;
	.downloadWrapper{
		text-align: center;
		a{
		    width: 113px;
		    height: 45px;
		    background-image: url(../images/platform/form_btn.png);
		    background-size: cover;
		    display: inline-block;
		    vertical-align: middle;
		    &.dim{
	    	    background-image: url(../images/platform/form_btn_disable.png);
		    }

		    .txtLink{
	    	    font-size: 14px;
			    color: #fff;
			    line-height: 45px;
			    text-align: center;
			    letter-spacing: 2px;
			    text-shadow: 0 0 6.8px rgba(42,32,4,0.7);
		    }
		}

		.game{
		    width: 90px;
		    height: 60px;
		    display: inline-block;
		    vertical-align: middle;
		    margin-left: -14px;
		}

		.allbet{
		    background-image: url(../images/platform/logo-allbet.png);
    		background-size: cover;
		}

		.allbet9{
		    background-image: url(../images/platform/logo-allbet9.png);
    		background-size: cover;
		}

		.sunbet{
		    background-image: url(../images/platform/logo-sunbet.png);
    		background-size: cover;
		}

		.live{
		    background-image: url(../images/platform/logo-live.png);
    		background-size: cover;
		}

		.vs{
		    background-image: url(../images/platform/logo-vs.png);
    		background-size: cover;
		}

		.ag{
		    background-image: url(../images/platform/logo-ag.png);
    		background-size: cover;
		}

		.n2{
		    background-image: url(../images/platform/logo-n2.png);
    		background-size: cover;
		}

		.kg{
		    background-image: url(../images/platform/logo-kg-v2.png);
    		background-size: cover;
		}
	}
}

.outWrap{
	&.platform{
		background:url('../images/platform/inside_bg.jpg') top center;
		background-size:cover;
	}
}

/** register **/
.cpatcha{
	display:inline-block;
	margin-right: 5px;
	vertical-align: middle;
}

input[type="radio"]{
    vertical-align: bottom;
}

input[name="security_code"]{
    height: 20px;
    width:25%;
    vertical-align: middle;

	@media(max-width:480px){
		width:33%;
	}
}

#previewWrapper{
	display:none;
	.sucessTag,.platItm{
		color:#FFF;
	}

}

.preview-padding{
	padding:0px 5%;
}

.previewDetail{
	p{
		color:#FFF !important;
	}
}

.text-center{
	text-align: center;
}

.finishWrapper{
	display:none;
}

.sucessTitle{
	p{
		color: #ecd052 !important;
    	font-size: 22px;
	}
	
}

.submitBtn{
	text-align: center;
	&.submit{
		// background: url(../images/register/submit_btn.jpg) no-repeat scroll 0 0 transparent;
		background: url(../images/form/submit_btn.png) center center no-repeat transparent;
		
		background-size: contain !important;
		border: medium none;
		display: block;
		// height: 25px;
		height:43px;
		margin-top: 10px;
		// text-indent: -9999px;
		// width: 80px;
		width:125px;
		color:#ffe5b4;
		line-height: 47px;
		box-sizing: border-box;
		text-shadow: 0px 0px 4px #ffeb3b;
		text-align: center;
		display:inline-block;
		cursor: pointer;
		outline: none;

		&:hover{
			background: url(../images/form/submit_btn_hover.png) center center no-repeat transparent;
			text-decoration: none;
		}
		
		@media(max-width:480px){
			background: url(../images/form/submit_btn.png) center center no-repeat transparent;
			background-size: contain !important;
			height: 71px;
			width: 202px;
			text-indent: 0px;
			text-align: center;
			color:#ffe5b4;
			text-shadow: 0px 0px 4px #ffeb3b;
			line-height: 74px;
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 1px;
		}
	}
}

.common-form-wrapper{
    border: #9c3e4d 1px solid;
    background: url(../images/inner_page/inner_content_bg.jpg) no-repeat top center #1d0b0e;
    padding: 5% 3%;
	color:#feffa8;
	
	input[type="radio"]{
		margin: 0px;
		margin-right: 2px;
		vertical-align: text-bottom;
	}

    @media(max-width:480px){
    	padding:5%;
    }

    input,select{
    	&:disabled {
	    	background: #9E9E9E;
	    	color:#FFF;
		}
    }

    .common-form-group{
    	margin-bottom: 7%;
    }

    h3{
    	color: #feffa8;
    	font-weight: bold;
    	font-size:15px;
    }

    .form-title-margin{
    	margin-bottom: 3%;
    }

    p{
    	color: #feffa8;
    }

    .register-form-label{
	    width: 23%;
	    line-height: 25px;
	    /* float: left; */
	    text-align: right;
	    padding-right: 10px;
	    display: inline-block;
	    box-sizing: border-box;
	    vertical-align: middle;
	    @media(max-width:480px){
    		width:100%;
    		text-align: left;
		    margin-bottom: 2%;
    	}
    }

    .register-form-input{
		width:76%;
    	box-sizing: border-box;
    	display:inline-block;
    	line-height: 25px;
    	float:right;
    	vertical-align: middle;
    	input,select{
    		&.wide{
				width:50%;
				box-sizing: border-box;
		   	 	min-height: 25px;

    			@media(max-width:480px){
    				width:100%;
    				&.open-amount{
    					width:89%;
    				}
				}
				
				@media(max-width:320px){
    				&.open-amount{
    					width:87%;
    				}
    			}
    		}
    	}

    	@media(max-width:480px){
    		width:100%;
    		line-height: 18px;
    	}
    }

    .register-input-rows{
    	margin:1% 0px;
    	clear: both;
    	display: inline-block;
    	width: 100%;
    	@media(max-width:480px){
    		margin:3% 0px;
    	}
    }

    .input-remarks{
    	margin-top: 10px;
		line-height: 1.5;
		word-break: break-word;
		// font-size:14px;
		// line-height: 1.5;
    }

    .payment-select{
    	margin-top: 2.5%;
    	ul{
    		li{
		        float: left;
			    vertical-align: middle;
			    padding-bottom: 20px;
			    padding-right: 20px;
			    width: 33.3%;
			    box-sizing: border-box;
			    display: inline-block;

			    @media(max-width:960px){
			    	width:50%;
			    }
    		}
    	}

    	.bank-radio{
    		width:13px;
    		vertical-align: middle;
		    margin: 7% 0;
    	}

    	.bank-label{
    		float:right;
    		width:87.5%;

    		@media(max-width:480px){
    			img{
    				width:100%;
    			}
    		}
    	}
    }

    .submit-btn-wrapper{
    	padding:2% 0%;
    	text-align: center;
    	.submit{
			// background: url(../images/register/submit_btn.jpg) no-repeat scroll 0 0 transparent;
			background: url(../images/form/submit_btn.png) center center no-repeat transparent;

			background-size: contain !important;
		    border: medium none;
		    display: block;
		    // height: 25px;
		    height:43px;
		    margin-top: 10px;
		    // text-indent: -9999px;
		    // width: 80px;
		    width:125px;
		    color:#ffe5b4;
		    line-height: 43px;
			text-shadow: 0px 0px 4px #ffeb3b;
		    text-align: center;
		    display:inline-block;
			cursor: pointer;
			outline: none;

			&:hover{
				background: url(../images/form/submit_btn_hover.png) center center no-repeat transparent;
			}
			
			@media(max-width:480px){
				background: url(../images/form/submit_btn.png) center center no-repeat transparent;
				background-size: contain !important;
				height: 71px;
				width: 202px;
				text-indent: 0px;
				text-align: center;
				color:#ffe5b4;
				text-shadow: 0px 0px 4px #ffeb3b;
				line-height: 74px;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 1px;
			}
    	}
    }

    .agent_ac_name_input{
    	width:46%;
    	@media(max-width:480px){
    		width:81%;
		}
		
		@media(max-width:320px){
    		width:80%;
    	}
    }

    .agent_percentage{
	    width: 52.5%;
	    @media(max-width:480px){
	    	width:100%;
	    }
    }
}

.form-order-list{
	list-style: decimal;
	padding-left: 3%;
	
	@media(max-width:480px){
		padding-left: 7%;
	}

	@media(max-width:320px){
		padding-left: 9%;
	}

    li{
	    line-height: 1.5;
    	padding:1% 0%;
    	a{
    		color:#FFF;
    		font-weight: bold;
    	}
    }
}

.form-separate-line{
	width:100%;
	height:1px;
	background:#feffa8;
	margin:2% 0px;
}

.border-top-line{
	border-top:1px solid #f0e893;
    margin-bottom: 2%;
}

.border-bottom-line{
	border-bottom:1px solid #f0e893;
    margin-top: 2%;
}

.highlight_font{
    font-size: 14px;
    font-family: arial;
    color: #FFFFFF;
    font-weight: bold;
    margin-left: 5px;
}

/** notice **/
.notice-rows{
	border-bottom:1px solid #FFF;
    color: #FFF;
    padding:3% 0px;
    .notice-date{
		margin-bottom: 5%;
		font-size:14px;
    }

    .notice-desc{
    	// padding-bottom: 3%;
		line-height: 1.5;
		font-size:14px;
    }
}

/** articles **/
.articles-rows{
	color:#FFF;
    padding: 1px 0%;
	.articles-date{
		display:inline-block;
		float:left;
		width:15%;
	}

	.articles-time{
		display:inline-block;
		float:left;
		width:25%;
	}

	.articles-title{
		display:inline-block;
		float:left;
		width:59%;
    	line-height: 18px;
	}

	clear:both;
}

/** news **/
#news-wrapper{
	.news-rows{
		color:#FFF;
        padding: 2% 1%;
		border-bottom: 1px solid #4f4142;
		
		.pagination-wrapper {
            padding: 0 3.5%;
			text-align: right;
			
			.pagination:last-child {
				margin: 0;
			}
		}

		.pagination {
            display: inline-block;
            padding: 3px 6px;
            margin: 0 10px 0 0;
            font-size: 14px;
            color: #fff;
            background: #353432;
			border: 1px solid #dbe1e6;
			
			&.active{
				background: #520606;
            	border: 1px solid 
			}
        }
		
		.news-date{
			display:inline-block;
			float:left;
			width:30%;
			text-align: center;
			line-height: 1.5;
			font-size:14px;
		}
		.news-title{
			display:inline-block;
			float:left;
			width:70%;
			line-height: 1.5;
			font-size:14px;
	    	a{
	    		color:#FFF;
	    	}
    	    padding-left: 3.5%;
    		box-sizing: border-box;
    	    background: url(../images/news/bullet.jpg) 6px center no-repeat;
    	    @media(max-width:480px){
    	    	padding-left: 7%;
    	    }
		}

		&:first-child{
		    padding: 5px;
			font-size:1.1em;
			color:#f4d0ac;
			font-weight: bold;
		    letter-spacing: 3px;
		    background: rgb(41,12,16);
			background: -moz-linear-gradient(top, rgba(41,12,16,1) 0%, rgba(54,8,8,1) 50%, rgba(41,12,16,1) 100%);
			background: -webkit-linear-gradient(top, rgba(41,12,16,1) 0%,rgba(54,8,8,1) 50%,rgba(41,12,16,1) 100%);
			background: linear-gradient(to bottom, rgba(41,12,16,1) 0%,rgba(54,8,8,1) 50%,rgba(41,12,16,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#290c10', endColorstr='#290c10',GradientType=0 );
			.news-title{
				text-align: center;
				background:none;
			}
		}

		clear:both;
	}
}

.news-details-wrapper{
	.news-content{
		padding:3%;
	}

	.news-title-wrapper{
		color:#FFF;
	    border-bottom: 1px solid #4f4142;
	    padding-bottom: 2%;
	    margin-bottom: 3%;
		.news-detail-title{
			font-size:16px;
			text-align: center;
			width:85%;
			box-sizing:border-box;
			float:left;
			line-height: 20px;
		    padding: 0px 2%;
		}
		.news-detail-date{
			width:15%;
			box-sizing:border-box;
			float:left;
		}

		@media(max-width:480px){
			padding-bottom: 4%;
		}
	}
}

a.back{
    // text-indent: -9999px;
    display: block;
	width: 65px;
    height: 26px;
	// background: url(../images/news/back.jpg) no-repeat;
	background:#4f080c;
	border:2px solid #d8b71e;
	text-align: center;
	color:#FFF;
	margin-top: 25px;

	@media(max-width:480px){
		margin-top: 10px;
		width: 120px;
		height: 30px;
		margin: auto;
	}

	.back-arrow{
		background:url(../images/back-arrow.svg) center center no-repeat;
		width: 9px;
		height: 25px;
		margin-right: 4px;
		display: inline-block;
		vertical-align: middle;
		@media(max-width:480px){
			height: 30px;
		}
	}

	.back-text{
		display:inline-block;
		vertical-align: middle;
		color:#d1c39e;
		line-height: 1;
		font-size: 16px;
		// @media(max-width:480px){
		// 	font-size: 16px;
		// }
	}
}

i{
	&.refresh-img{
		background:url(../images/form/refresh.png) center center no-repeat;
		background-size:100%;
		// -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/form/refresh.png',sizingMethod='scale')";
  //       filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/form/refresh.png',sizingMethod='scale');
	    display: inline-block;
	    width: 20px;
	    height: 20px;
	    vertical-align: middle;
	    margin-right: 5px;
	}
}

.refresh-a-tag{
	&:hover{
		text-decoration: none !important;
	}
}

.refresh-text{
	@media(max-width:480px){
		display:none;
	}
}

