html{
	width: 100%;
	min-width: 960px;
}

.page-content{
	overflow: hidden;
	width: 100%;
}

.outWrap{
	width: 960px;
	margin: auto;
	position: relative;

}

