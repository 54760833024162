//- Smaller than desktop
$screen-tablet: 960px;

//- Smaller than tablet
$screen-mobile: 650px;

$header-height: 64px;

.mobileVisible{
    display: none;
}

#menuWrapper,
#contactWrapper {
    position: absolute;
    width: 30px;
    height: 30px;
    background-position: 100% 100%;

    &.menuOpen{
        background-position: center top;
    }
}

#menuWrapper{
    background-image: url(../images/header/nav/menu_btn.png);
    background-size: cover;
    top: 15px;
    left: 15px;
}

#contactWrapper{
    background-image: url(../images/header/nav/contact_btn.png);
    background-size: cover;
    top: 17px;
    right: 15px;
}



@media(min-width: $screen-tablet){
    #nav{
        .menuNav{
            > li:hover{
                ul {
                    display: block!important;
                }
            }
        }
    }


}

@media(max-width:900px){
    .vs-live{
        .half-text{
            width:100%;
            &:first-child{
                width:100%;
            }
        }
    }

    .common-games-box{
        .games-box-desc-wrapper{
            .games-box-img{
                width:100%;
                float:none;
                img{
                    width:100%;
                }
            }

            .games-box-desc{
                width:100%;
                min-height: 100px;
            }
        }
    }

    .common-gaming-box-wrapper{
        &.tickets{
            .common-games-box{
                .games-box-desc-wrapper{
                    .games-box-desc{
                        min-height: 150px;
                    }
                }
            }
        }
    }
}

@media(max-width: $screen-tablet){
    html{
        min-width: 100%;
    }

    .common-gaming-tag-wrapper{
        text-align: center;
        .gaming-tabs{
            margin-bottom: 5px;
        }
    }

    .mobileVisible{
        display: block!important;
    }

    .pcVisible{
        display: none!important;
    }

    .outWrap{
        width: 100%;
    }

    .page-content{
        padding-top: $header-height;
    }

    #contactMenu{
        display: block;
    }

    #header .rightWrap,
	#memberZone,
	#newsInfo,
	#brand,
	#information,
	#index_services_box{
		display: none;
	}

    #nav{
        padding-top: $header-height;
        background: none;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: url('../images/header/nav/inside_bg2.jpg');
        background-size: cover;
        transform: translateX(-100%);
        overflow: scroll;
        transition: .2s;

        &.menuOpen{
            transform: translateX(0%);
        }


        .menuNav{
            > li{
                width: 80%;
                margin: auto;
                float: none;

                > a{
                    padding: 12px 15px;
                    border-bottom: 1px solid #393939;
                    border-right: 0px;
                    color: #fff1cd;
                    font-size: 16px;
                    text-align: left;
                    width: 100%;
                    box-sizing: border-box;
                    margin: 0px;
                }
                
                ul.sub_nav{
                    display: none;
                    position: relative;
                    top: auto;

                    li{
                        float: none;
                        background-color: transparent;
                        padding: 2px 0px;
                        text-align: left;
                        padding-left: 35px;
                        background:none;
                        border-bottom: none;

                        &:hover{
                            background:none;
                        }

                        a{
                            color: #fff;
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
                }

                &.active{
                    ul.sub_nav{
                        display: block;
                    }

                    .menuArrow{
                        background-position: center top;
                    }
                }
            }
        }
    }

    #header{
        text-align: center;
        // background: url('../images/header/nav/top_bg-repeat.jpg') repeat;
        background: url('../images/header/nav/top_bg.jpg') repeat;
        background-size: 320px $header-height;
        height: $header-height;
        position: fixed;
        z-index: 101;

        .leftWrap{
            float: none;
            display: inline-block;
        }

        .spotlight{
            width: 320px;
            height: 65px;
            // background: url(../images/header/nav/top_bg.jpg);
            background:none;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: auto;
        }

        #logo{
            width: 175px;
        }
    }

    #index_banner{
        width: 100%;
        height: auto;
        margin: 0px;

        .slick-slide {
            img{
                width: 100%;
            }
        }

        a.btn1,
        a.btn2{
            width: 23%;
            height: 10%;
            position: absolute;
            display: block;
            top: 80%;
        }

        a.btn1{
            left: 36%;
        }

        a.btn2{
            left: 63%;
        }

        a.btn1_b,
        a.btn2_b{
            top: 50%;
            width: 26%;
            height: 20%;
            margin-top: 5%;
            left: 40%;
        }

        a.btn1_b{
            margin-left: -15%;
        }

        a.btn2_b{
            margin-left: 10%;
        }
        
    }

    #indexFormWrapper{
        display: block;
    }

    .common-right-wrapper{
        width:70%;
        margin-left:0px;
        margin: auto;
        float:none;
    }

    .common-left-wrapper{
        width:100%;
        margin: auto;
        float:none;
        display:none;
    }

    .common-mobile-align-wrapper{
        padding-top: 10%;
    }

}


@media(max-width: $screen-mobile){

    .common-form-wrapper{
        .payment-select{
            ul{
                li{
                    padding-right: 8px;
                }
            }
        }
    } 

    .inner_contactus_background{
        .content_table{
            .invitation{
                font-size: 14px;
                width: 156px;
                height: 44px;
                line-height: 49px;
                padding-left: 8px;
            }
        }
    } 

    .inner_contactus_background{
        padding:2%;
        .content_table{
            width:85%;
            margin:auto;
        }
    }

    .common-flagship-box{
        &.three{
            width:50%;
        }

        &.four{
            width:40%;
        }
    }

    .common-gaming-box-wrapper{
        &.tickets{
            .common-games-box{
                .games-box-desc-wrapper{
                    .games-box-desc{
                        min-height: auto;
                    }
                }
            }
        }
    }

    .common-games-box{
        width:100%;
        clear:both;
        .games-box-desc-wrapper{
            .games-box-desc{
                min-height: auto;
                margin-bottom: 5%;
            }
        }
    }

    .promotion-rows{
        .promotion-img{
            width:100%;
        }

        .promotion-desc-wrapper{
            width:100%;
            // padding: 20px 0px 0px 20px;
            padding: 20px 10px 0px 10px;
        }
    }

    .flow-list{
        padding-left: 7%;
    }

    .common-mobile-align-wrapper{
        padding-top: 20%;
    }

    .common-right-wrapper{
        width:95%;
    }

    #index_banner{
        .slick-dots {
            li{
                width: 15px;
                height: 15px;
            }
        }
    }

    .platformWrapper{
        .platformItm{
            .platform_pop{
                display: none!important;
            }
        }
    }

    #information{
        text-align: center;
        #index_left{
            float: none;
            display: inline-block;
        }
        
        #notice{
            display: inline-block;
        }
    }

    #newsInfo{
        text-align: center;
     
        #contactUs{
            float: none;
            display: inline-block;
        }
    }

    #content-wrapper.inex-popup-banner{
        .promotion{
            .promotion-hover{
                .promo-login,
                .promo-view{
                    margin-top: 10%;
                    width: 30%;
                }
            }
        }

    }
}