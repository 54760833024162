.validate-msg{
	color: #ffa2a2;
	display:none;
    padding: 1% 0%;
	&.active{
		display:block;
	}
}

.validateMsg{
    color: #ffa2a2;
    display:none;
    padding: 1% 0%;
    &.active{
        display:block;
    }
}

.errorIcon{
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    img{
    	width:100%;
    }

    background:url('../images/form/error.png') center center no-repeat;
    background-size:100%;
}

.error-text,
.errorTxt{
    display: inline-block;
    vertical-align: middle;
    color: #ffa2a2;
    font-size:13px;
}

.error{
    ul{
        border: solid 1px #cb2026;
        background: #f6cbca;
        color: #d02127;
        padding: 4px 0;
        li{
            margin: 0;
            padding: 2px 10px;
        }
    }
}
