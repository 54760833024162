a#news {
        
    width: 100%;
    height: 25px;
    border-bottom: 1px solid #9e8858;
    background-color: #171717;
    top: 64px;
    position: relative;

    
    .newsTitle {
        position: absolute;
        top: 0;
        left: 0;
        width: 65px;
        height: 25px;
        background: url('../images/index/news.jpg');
        text-align: left;
        background-size: 100% 100%;
        z-index: 2;
    }
    .newsMarquee {
        opacity: 0;
        width: 100%;
        height: 25px;
        overflow: hidden;
        li {
            font-family: Arial, SimHei, 黑体, STHeiti, 华文黑体, serif;
            display: inline-block;
            list-style: none;
            line-height: 25px;
            padding-right: 50px;
            color: #fff;
            font-size:14px;
            -webkit-text-size-adjust: none;
        }
    }
    .newsArrow {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        background-color: #171717;
        
        img {
            display: block;
            width: 25px;
        }
    }
}