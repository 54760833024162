.status-light{
    width: 9px;
    margin-left: 5px;
    height: 9px;
    border-radius: 50%;
    border: 1px solid #efb61d;
    display: inline-block;
    box-shadow: 1px -1px 5px #000;
    vertical-align: middle;
    background: rgba(209,201,209,1);
    background: -moz-linear-gradient(top, rgba(209,201,209,1) 0%, rgba(89,89,89,1) 95%, rgba(89,89,89,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(209,201,209,1)), color-stop(95%, rgba(89,89,89,1)), color-stop(100%, rgba(89,89,89,1)));
    background: -webkit-linear-gradient(top, rgba(209,201,209,1) 0%, rgba(89,89,89,1) 95%, rgba(89,89,89,1) 100%);
    background: -o-linear-gradient(top, rgba(209,201,209,1) 0%, rgba(89,89,89,1) 95%, rgba(89,89,89,1) 100%);
    background: -ms-linear-gradient(top, rgba(209,201,209,1) 0%, rgba(89,89,89,1) 95%, rgba(89,89,89,1) 100%);
    background: linear-gradient(to bottom, rgba(209,201,209,1) 0%, rgba(89,89,89,1) 95%, rgba(89,89,89,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1c9d1', endColorstr='#595959', GradientType=0 );

    &.online{
        background: rgba(22,224,22,1);
        background: -moz-linear-gradient(top, rgba(22,224,22,1) 0%, rgba(210,255,82,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(22,224,22,1)), color-stop(100%, rgba(210,255,82,1)));
        background: -webkit-linear-gradient(top, rgba(22,224,22,1) 0%, rgba(210,255,82,1) 100%);
        background: -o-linear-gradient(top, rgba(22,224,22,1) 0%, rgba(210,255,82,1) 100%);
        background: -ms-linear-gradient(top, rgba(22,224,22,1) 0%, rgba(210,255,82,1) 100%);
        background: linear-gradient(to bottom, rgba(22,224,22,1) 0%, rgba(210,255,82,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#16e016', endColorstr='#d2ff52', GradientType=0 );

    }

    &.offline{
        background: rgba(209,201,209,1);
        background: -moz-linear-gradient(top, rgba(209,201,209,1) 0%, rgba(89,89,89,1) 95%, rgba(89,89,89,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(209,201,209,1)), color-stop(95%, rgba(89,89,89,1)), color-stop(100%, rgba(89,89,89,1)));
        background: -webkit-linear-gradient(top, rgba(209,201,209,1) 0%, rgba(89,89,89,1) 95%, rgba(89,89,89,1) 100%);
        background: -o-linear-gradient(top, rgba(209,201,209,1) 0%, rgba(89,89,89,1) 95%, rgba(89,89,89,1) 100%);
        background: -ms-linear-gradient(top, rgba(209,201,209,1) 0%, rgba(89,89,89,1) 95%, rgba(89,89,89,1) 100%);
        background: linear-gradient(to bottom, rgba(209,201,209,1) 0%, rgba(89,89,89,1) 95%, rgba(89,89,89,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1c9d1', endColorstr='#595959', GradientType=0 );
    }
}

#contactMenu{
    .outWrap{
        .status-light{
            position: absolute;
            bottom: 28%;
            left: 50%;
        }
    }
}


#qqBtn{
    .qqStatus{
        .text{
            display:inline-block;
            vertical-align: middle;
            font-size:15px;
        }
    }
}


#header-wrapper-bg{
    // background: url(../images/cny2020/1W_CNY2020_bg_top.jpg) no-repeat center top #000000;
    // background-size: 100% 153px;
    background-size: auto 152px;
    image-rendering: pixelated;
}

#header {

    a#logo {
        display: block;
        img{
            width: 100%;
        }
    }

    .leftWrap {
        float: left;
    }

    .rightWrap {
        float: right;

        .header_tools,
        .header_time {
            float: right;
            margin: 10px 0 0 0;
            z-index: 2;

            li {

                float: left;
                a {

                    color: #c7c47c;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }

        .header_tools{
            li {
                line-height: 15px;
                border-right: 1px solid #c7c47c;
                padding: 0px 5px;

                &.last {
                    border-right: 0px;
                }
            }
        }

        .header_time {
            padding-right: 15px;
            li {

                span {
                    display: inline-block;
                    vertical-align: middle;

                    &.timer {
                        width: 13px;
                        height: 13px;
                        background: url(../images/header_clock.gif) center no-repeat;
                    }
                }
            }
        }

        .telWrap {
            padding-top: 35px;
        }

        div.button_logos {
            position: absolute;
            top: 64px;
            right: 0;
            // background: url(../images/header/phonebet/platform_logos6-v2.png) no-repeat;

            ul.header_button {
                display: inline-block;
                vertical-align: middle;
                // margin: 50px 0 0 0;
                width: 385px;
                // width:200px;
                height: 25px;

                li {
                    display: inline-block;
                    vertical-align: top;
                    padding: 0px 20px;
                    box-sizing: border-box;

                    &.AG,
                    &.battle {
                        width: 100%;
                    }
                }

                a {
                    &.navBtn {
                        width: 84px;
                        height: 25px;
                        margin: 0px 5px;
                        display: block;
                        background-repeat: no-repeat;
                        background-position: top left;

                        text-align: center;
                        color:#FFF;
                        line-height: 25px;
                        text-shadow: 1px 1px 1px #000;

                        &:hover{
                            // color:#d1b01c;
                        }
                    }

                    background:url('../images/header/red_btn.png') center center no-repeat;
                    &:hover{
                        background:url('../images/header/red_btn_hover.png') center center no-repeat;
                    }
                   
                }

                ul {

                    &.tab {
                        position: relative;
                        display: block;
                        float: left;
                        cursor: pointer;

                        .tablink {
                            position: relative;
                            padding: 0px 0px;
                            line-height: 0px;
                            display: block;
                            z-index: 3;
                            float: left;

                            a {
                                text-decoration: none;
                            }
                        }
                    }

                    &.subnav {
                        font-size: 12px;
                        font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
                        font-weight: lighter;
                        letter-spacing: 0.5px;
                        float: right;
                        width: 248px;
                        height: 230px;
                        // padding-top: 60px;
                        margin-top: 14px;

                        .loginLabel {
                            margin: 0 auto 5px;
                            display: block;
                            font-size: 15px;
                            letter-spacing: 1px;
                            font-weight: bold;
                            text-shadow: 0px 0px 2px #000, 0px 0px 2px #000;
                            color: #e8d1a5;
                            margin: 10px 0px;
                            text-align: center;
                        }

                        .loginlink {
                            position: relative;
                            text-decoration: none;
                            text-indent: 0px;
                            color: #FFF;
                            display: block;
                            margin-bottom: 5px;
                            text-align: center;
                            // font-weight: bold;
                            background:none;
                            font-size:13px;
                            &:hover{
                                color:#FF0;
                                background:none;
                            }
                        }

                        // &.sunbet {
                        //     background: url(../images/header/dropdown/sunbet.png) no-repeat;
                        // }
                        // &.allbet {
                        //     background: url(../images/header/dropdown/allbet.png) no-repeat;
                        // }
                        // &.live999 {
                        //     background: url(../images/header/dropdown/live.png) no-repeat;
                        // }
                        // &.battle {
                        //     background: url(../images/header/dropdown/ag.png) no-repeat;
                        // }

                        background: url(../images/header/dropdown/dropdown_bg_empty.png) no-repeat;
                        .dropdown-logos{
                            text-align: center;
                            img{
                                height: 65px;
                                margin-bottom: -15px;
                            }
                        }
                    }

                    &.dropdown {
                        ul {
                            display: none;

                            &.show{
                                position: absolute;
                                display: block;
                                top: 10px;
                                right: -65px;
                                z-index: 9999;
                                float: right;
                            }
                        }
                        &:hover ul {
                            position: absolute;
                            display: block;
                            // top: 10px;
                            top: 65px;
                            right: -65px;
                            z-index: 9999;
                            float: right;
                        }
                        
                    }
                }
            }
        }

        .header-platform-box{
            max-width:95px;
            img{
                max-width:100%;
                margin-bottom: -7px;
            }
        }



        .moreLogin {
            position: relative;
            display: inline-block;
            vertical-align: top;
            padding-bottom: 20px;
        }

        .moreBtn {
            display: block;
            width: 52px;
            height: 57px;
            overflow: hidden;
            margin-top: 15px;
            background:url('../images/header/more_platform.png') center center no-repeat;
            color:#FFF;
            text-align: center;
            line-height: 1.3;
            font-size: 14px;
            font-weight: bold;
            text-shadow: -2px 1px 2px #000;
            padding: 6px 5px;
            box-sizing: border-box;
            letter-spacing: 1px;

            .more_platform_arrow{
                background:url('../images/header/more_platform_arrow.svg') center center no-repeat;
                height: 7px;
                margin-top: 3px;
            }

            &:hover{
                background:url('../images/header/more_platform_hover.png') center center no-repeat;
            }

            &:hover img {
                margin-top: -57px;
            }
        }

        .moreLogin:hover .submenu,
        .submenu:hover,
        .moreBtn img {
            display: block;
        }

        .submenu {
            position: absolute;
            display: none;
            top: 80px;
            left: -720px;
            z-index: 9999;
            background-image: url(../images/header/dropdown/others_bg4.png);
            background-repeat: no-repeat;
            width: 777px;
            height: 228px;
            padding: 75px 0 20px;
            box-sizing: border-box;
            .linkList {
                display: inline-block;
                vertical-align: top;
                width: 193px;
                text-align: center;
                box-sizing: border-box;
                float: left;
                span {
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0 5px;
                }
                .loginLabel{
                    display: block;
                    text-align: center;
                    margin: 0 auto 10px;
                    font-size: 15px;
                    letter-spacing: 1px;
                    font-weight: bold;
                    text-shadow: 0px 0px 2px #000, 0px 0px 2px #000;
                    color: #e8d1a5;
                    margin: 10px 0px;
                    text-align: center;

                }
                a {
                    display: block;
                    text-align: center;
                    color: #fff;
                    font-size: 12px;
                    font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
                    font-weight: normal;
                    letter-spacing: 0.5px;
                    margin-bottom: 5px;
                    text-decoration: none;


                    &:hover {
                        color: #ff0;
                    }
                }
            }
        }
    }
}

#nav {
    position: relative;
    background: url(../images/header/nav_bg.png) repeat-x;
    z-index: 999;
    height: 41px;
    clear: both;

    .menuNav {
        >li {
            float: left;
            position: relative;

            .menuArrow{
                width: 15px;
                height: 16px;
                background-image: url(../images/header/nav/menu_arrow.png);
                background-position: 100% 100%;
                background-size: cover;
                // -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/header/nav/menu_arrow.png',sizingMethod='scale')";
                // filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/header/nav/menu_arrow.png',sizingMethod='scale');
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
            }

            >a {
                display: block;
                width: 136px;
                text-align: center;
                font-weight: 500;
                border-right: 1px solid rgba(107, 86, 79, 1);
                color: #fff9ad;
                font-family: Arial, SimHei, STHeiti, serif;
                padding: 0px 0px;
                margin: 12px 0px 12px;
                font-size:15px;
            }

            &:hover{
                a{
                    color:#d1b01c;
                }
            }

            ul.sub_nav {
                display: none;
                position: absolute;
                z-index: 1;
                top: 37px;

                li {
                    width: 137px;
                    margin: 0px;
                    padding: 8px 0px;
                    // background-color: red; 
                    // overflow: hidden;
                    background: rgb(27,7,2);
                    background: -moz-linear-gradient(top, rgba(27,7,2,1) 1%, rgba(66,4,0,1) 100%);
                    background: -webkit-linear-gradient(top, rgba(27,7,2,1) 1%,rgba(66,4,0,1) 100%);
                    background: linear-gradient(to bottom, rgba(27,7,2,1) 1%,rgba(66,4,0,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1b0702', endColorstr='#420400',GradientType=0 );
                    text-align: center;
                    float: left;
                    position: relative;
                    border-bottom: 1px solid #5e1e16;
                    font-size: 15px;

                    &:hover{
                        background: rgb(128,8,0);
                        background: -moz-linear-gradient(top, rgba(128,8,0,1) 0%, rgba(70,4,0,1) 100%);
                        background: -webkit-linear-gradient(top, rgba(128,8,0,1) 0%,rgba(70,4,0,1) 100%);
                        background: linear-gradient(to bottom, rgba(128,8,0,1) 0%,rgba(70,4,0,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#800800', endColorstr='#460400',GradientType=0 );
                    }


                    a {
                        font-family: Arial, SimHei, STHeiti, serif;
                        color: #e9cb00;
                        width: 137px;
                        height: 25px;
                        margin: 0px;
                        font-weight: bold;
                        overflow: hidden;
                    }
                }
            }

            

            &:last-child {
                a {
                    border-right: 0px;
                }
            }
        }
    }
}


#contactMenu{
    height: 100%;
    position: fixed;
    top: 64px;
    right: 0;
    z-index: 9999;
    background-image: url(../images/header/nav/inside_bg2.jpg);
    transform: translateX(100%);
    transition: .2s;
    padding: 20px;
    padding-bottom: 95px;
    display: none;

    &.menuOpen{
        transform: translateX(0%);
    }

    .title{
        img{
            display: block;
            width: 70px;
        }

        &.agentTitle{
            margin-top: 35px;
        }
    }

    a{
        position: relative;
        display: block;
        width: 100%;
        max-width: 570px;
        margin: 10px 0;
        color: #fff;

        p{
            position: absolute;
            top: 52%;
            left: 20%;
            letter-spacing: 3px;
            font-family: Arial, SimHei, 黑体, STHeiti, 华文黑体, serif;
        }

        img{
            display: block;
            margin: auto;
            width: 100%;
        }
    }
}



