@import '../partials/class.scss';


/** banner button wrapper **/
.delivery-btn-wrapper{
    position: absolute;
    bottom: 30px;
    left: -2%;
    text-align: center;
    width: 100%;
    z-index: 2;

    &.mobile{
        left: -25%;
        right: 0px;
        margin: auto;
        bottom: 9%;
        max-width: 50%;
        .egg-btn{
            margin: 0px 5%;
            img{
                width:100%;
            }

            &.last{
                margin-left: 1%;
            }
        }
    }

    .egg-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;
        margin: 0px 1%;

        &.mobile{
            width: 42%!important;
        }

        img{
            display:inline-block;
            width:90%;
        }

        &.last{
            margin-left: -1%;
        }
    }
}

.coins-btn-wrapper{
    position: absolute;
    bottom: 43px;
    text-align: center;
    width: 100%;
    z-index: 2;
    width: 100%;

    &.mobile{
        left: 0px;
        right: 0px;
        margin: auto;
        bottom: 12%;
        max-width: 50%;
        .egg-btn{
            margin: 0px 5%;
            img{
                width:100%;
            }

            &.last{
                margin-left: 1%;
            }
        }
    }

    .egg-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;
        margin: 0px 1%;

        &.mobile{
            width: 42%!important;
        }

        img{
            display:inline-block;
            width:90%;
        }

        &.last{
            margin-left: -1%;
        }
    }
}

.big-face-off-btn-wrapper{
    position: absolute;
    bottom: 32px;
    left: 0px;
    text-align: center;
    width: 100%;
    z-index: 2;
    width: 100%;

    &.mobile{
        left: 0px;
        right: 0px;
        margin: auto;
        bottom: 6%;
        max-width: 50%;
        .egg-btn{
            margin: 0px 5%;
            img{
                width:100%;
            }

            &.last{
                margin-left: 1%;
            }
        }
    }

    .egg-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;
        margin: 0px 3%;

        &.mobile{
            width: 42%!important;
        }

        img{
            display:inline-block;
            width:90%;
        }

        &.last{
            margin-left: -1%;
        }
    }
}

.summer-btn-wrapper{
    position: absolute;
    bottom: 22px;
    left: -33px;
    text-align: center;
    width: 100%;
    z-index: 2;
    width: 100%;

    &.mobile{
        left: 0px;
        right: 0px;
        margin: auto;
        bottom: 6%;
        max-width: 50%;
        .egg-btn{
            margin: 0px 5%;
            img{
                width:100%;
            }

            &.last{
                margin-left: 1%;
            }
        }
    }

    .egg-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;
        margin: 0px 1%;

        &.mobile{
            width: 42%!important;
        }

        img{
            display:inline-block;
            width:90%;
        }

        &.last{
            margin-left: -1%;
        }
    }
}

.june-egg-btn-wrapper{
    position: absolute;
    bottom: 26px;
    margin-left: -66px;
    width: 100%;
    z-index: 2;
    text-align: center;

    @media(max-width:480px){
        bottom:13px;
    }

    &.mobile{
        left: 27%;
        max-width: 42%;

        @media(max-width:698px){
            left: 29%;
            bottom: 22px;
            // max-width: 33%;
            // padding-left: 10%;
        }

        @media(max-width:580px){
            left: 31%;
            bottom: 15px;
            max-width: 44%;
        }

        @media(max-width:480px){
            left: 34%;
            bottom: 13px;
            max-width: 43%;
        }

        @media(max-width:375px){
            left: 36%;
        }

        @media(max-width:320px){
            left: 39%;
            bottom:10px;
        }
    }

    .xin-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;
        margin-left: 1%;

        @media(max-width:960px){
            margin-left: 4%;
        }

        &.mobile{
            width: 46% !important;
        }

        img{
            display:inline-block;
        }


        &.last{
          
            
        }
    }
}

.dragon-btn-wrapper{
    position: absolute;
    bottom: 23px;
    padding-left: 77px;
    width: 100%;
    z-index: 2;
    text-align: center;

    @media(max-width:480px){
        bottom:13px;
    }

    &.mobile{
        left: 38%;
        max-width: 32%;

        @media(max-width:698px){
            max-width: 33%;
            padding-left: 10%;
        }
    }

    .xin-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;
        margin-left: 1%;

        @media(max-width:960px){
            margin-left: 4%;
        }

        &.mobile{
            width: 46% !important;
        }

        img{
            display:inline-block;
        }


        &.last{
          
            
        }
    }
}


.xin-btn-wrapper{
    position: absolute;
    bottom: 26px;
    text-align: center;
    width: 100%;
    z-index: 2;
    width: 100%;

    &.mobile{
        left: 0px;
        right: 0px;
        margin: auto;
        bottom: 12%;
        max-width: 50%;
        .egg-btn{
            margin: 0px 2%;
            img{
                width:100%;
            }

            &.last{
                margin-left: 0%;
            }
        }
    }

    .egg-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;
        margin: 0px 0%;

        &.mobile{
            width: 42%!important;
        }

        img{
            display:inline-block;
            width:90%;
        }

        &.last{
            margin-left: 5%;
        }
    }
}

.gold-btn-wrapper{
    position: absolute;
    bottom: 26px;
    text-align: center;
    width: 100%;
    z-index: 2;
    width: 100%;

    &.mobile{
        left: 0px;
        right: 0px;
        margin: auto;
        bottom: 12%;
        max-width: 50%;
        .egg-btn{
            margin: 0px 5%;
            img{
                width:100%;
            }

            &.last{
                margin-left: 1%;
            }
        }
    }

    .egg-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;
        margin: 0px 1%;

        &.mobile{
            width: 42%!important;
        }

        img{
            display:inline-block;
            width:90%;
        }

        &.last{
            margin-left: -1%;
        }
    }
}

.king-btn-wrapper{
    position: absolute;
    bottom: 25px;
    width: 100%;
    z-index: 2;
    text-align: center;

    &.mobile{
       left: 0;
        bottom: 8%;
        right: 0px;
        margin: auto;
        max-width: 50%;
        .egg-btn{
            img{
                width:100%;
            }
        }
    }

    .egg-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;

        &.mobile{
            width: 49%!important;
            box-sizing: border-box;
            padding: 0px 3%;
        }

        img{
            display:inline-block;
            width:90%;
        }
    }
}

.yuen-btn-wrapper{
    position: absolute;
    bottom: 51px;
    width: 100%;
    z-index: 2;
    text-align: left;
    padding-left: 17%;

    &.mobile{
        left: -6%;
        bottom: 21%;
        right: 0px;
        max-width: 54%;
        .egg-btn{
            img{
                width:100%;
            }
        }
    }

    .egg-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;

        &.mobile{
            width: 49%!important;
            box-sizing: border-box;
            padding: 0px 3%;
        }

        img{
            display:inline-block;
            width:90%;
        }
    }
}

.draw-btn-wrapper{
    position: absolute;
    bottom: 25px;
    z-index: 2;
    text-align: center;
    width: 100%;

    &.mobile{
        left: 0px;
        bottom: 8%;
        right: 0px;
        margin: auto;
        max-width: 50%;
        .egg-btn{
            img{
                width:100%;
            }
        }
    }

    .egg-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;

        &.mobile{
            width: 49%!important;
            box-sizing: border-box;
            padding: 0px 3%;
        }

        img{
            display:inline-block;
            width:90%;
        }
    }
}

.fish-btn-wrapper{
    position: absolute;
    bottom: 25px;
    z-index: 2;
    text-align: center;
    width: 100%;

    &.mobile{
        left: 0px;
        bottom: 6%;
        right: 0px;
        margin: auto;
        max-width: 45%;
        .egg-btn{
            img{
                width:100%;
            }
        }
    }

    .egg-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;
        padding: 0px 10px;

        &.mobile{
            width: 49%!important;
            box-sizing: border-box;
            padding: 0px 3%;
        }

        img{
            display:inline-block;
            width:90%;
        }
    }
}

.egg-btn-wrapper{
    position: absolute;
    bottom: 25px;
    z-index: 2;
    text-align: center;
    width: 100%;

    &.mobile{
        left: 0px;
        bottom: 12%;
        right: 0px;
        margin: auto;
        max-width: 55%;
        .egg-btn{
            img{
                width:100%;
            }
        }
    }

    .egg-btn{
        display:inline-block !important;
        position: relative !important;
        width:auto !important;
        padding: 0px 10px;

        &.mobile{
            width: 49%!important;
            box-sizing: border-box;
            padding: 0px 3%;
        }

        img{
            display:inline-block;
            width:90%;
        }
    }
}

.xin-banner-wrapper{
    position: relative;
    a{
        position: relative !important;
    }
}

/** iframe **/
.iframe-wrapper{
    position: relative;
    .iframe-scroll-bar-overlay{
        width:18px;
        height: 100%;
        position: absolute;
        right:0px;
        top:0px;
        background: rgba(44,14,16,1);
        background: -moz-linear-gradient(top, rgba(44,14,16,1) 0%, rgba(29,11,14,1) 1%, rgba(30,11,15,1) 28%, rgba(29,11,14,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(44,14,16,1)), color-stop(13%, rgba(29,11,14,1)), color-stop(28%, rgba(30,11,15,1)), color-stop(100%, rgba(29,11,14,1)));
        background: -webkit-linear-gradient(top, rgba(44,14,16,1) 0%, rgba(29,11,14,1) 1%, rgba(30,11,15,1) 28%, rgba(29,11,14,1) 100%);
        background: -o-linear-gradient(top, rgba(44,14,16,1) 0%, rgba(29,11,14,1) 1%, rgba(30,11,15,1) 28%, rgba(29,11,14,1) 100%);
        background: -ms-linear-gradient(top, rgba(44,14,16,1) 0%, rgba(29,11,14,1) 1%, rgba(30,11,15,1) 28%, rgba(29,11,14,1) 100%);
        background: linear-gradient(to bottom, rgba(44,14,16,1) 0%, rgba(29,11,14,1) 1%, rgba(30,11,15,1) 28%, rgba(29,11,14,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c0e10', endColorstr='#1d0b0e', GradientType=0 );
        
        @media(max-width:960px){
            display:none;
        }
    }
}

/** iframe **/

.promotion-iframe{
    @media(max-width:960px){
        height: 667px;
    }

    html{
        body{
            overflow: auto;
        }
    }
}

/** banner button wrapper **/

#memberZone {
    width: 195px;
    height: 280px;
    background: url(../images/index/middle_tools_bg.png) no-repeat;
    text-align: center;
    float: left;
    font-weight: bold;

    ul {
        padding-top: 15px;
        padding-bottom: 10px;
        li {
            width: 75%;
            margin: auto;
            border-bottom: 1px solid #cec66e;
            a {
                display: block;
                padding: 8px 0px;
                font-size: 18px;
                color: #fff9ad;
                font-weight: bold;
            }

            &:hover{
                a{
                    color:#d1b01c;
                }
            }
        }
    }
}


.service-title{
    background:url('../images/index/services/title_bg.png') center center no-repeat;
    text-align: center;
    width:190px;
    height: 50px;
    position: relative;
    .service-title-text{
        position: absolute;
        left: 0px;
        right: 0px;
        font-size: 14px;
        bottom: 10px;
        font-weight: bold;
        color:#e8e251;
        text-shadow: 2px 2px 1px #000;
        letter-spacing: 2px;
    }
}

.index-padding{
    // padding-top: 0.5%;
    // background: url(../images/xmas_bot_bg.jpg) no-repeat center top #000000;
}

.common-title-wrapper{
    .page-title{
        color:#ecd052;
        font-size:22px;
    }
}

#index_banner {
    opacity: 0;
    position: relative;
    width: 760px;
    height: 276px;
    float: right;
    margin: 3px 0 0 0;
    margin-left: -197px;
    overflow: hidden;
    z-index: 99;
    border:1px solid #c79502;
    
    .slick-slide {
        position: relative;
    }

    .slick-dots {
        bottom: 0;
        li button:before {
            display: none;
        }
    }
    .slick-dots li {
        background: none;
        background-image: url(../images/bullet.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 28px;
        height: 28px;
        opacity: 1;
        margin: 0;
    }
    li.slick-active {
        background: none;
        background-image: url(../images/bullet_active.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 28px;
        height: 28px;
    }

    .slick-arrow {
        width: 42px;
        height: 42px;
        z-index: 10;
        background-position: center top;

        &:hover {
            background-position: center bottom;
        }

        &:before {
            display: none;
        }
    }

    .slick-next {
        background-image: url(../images/banner-right.png);

        right: 10px;
    }

    .slick-prev {
        background-image: url(../images/banner-left.png);
        left: 10px;
    }

    a {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &.btn1 {
            position: absolute;
            display: block;
            top: 218px;
            left: 30%;
            width: 141px;
            height: 31px;
        }
        &.btn2 {
            position: absolute;
            display: block;
            top: 218px;
            left: 51%;
            width: 141px;
            height: 31px;
        }
        &.btn1_a {
            position: absolute;
            display: block;
            top: 206px;
            left: 30%;
            width: 141px;
            height: 31px;
        }
        &.btn2_a {
            position: absolute;
            display: block;
            top: 206px;
            left: 51%;
            width: 141px;
            height: 31px;
        }
        &.btn1_b {
            position: absolute;
            display: block;
            top: 176px;
            left: 30%;
            width: 141px;
            height: 31px;
        }
        &.btn2_b {
            position: absolute;
            display: block;
            top: 176px;
            left: 51%;
            width: 141px;
            height: 31px;
        }
    }
}

.platformWrapper {
    // display:none;

    padding-top: 3px;
    position: relative;
    clear: both;
    .platformItm {
        position: relative;
        float: left;
        margin-right: 4px;

        &:hover{
            .platform_pop{
                display: block;
                opacity: 1;
                visibility: visible;
                transition: all .1s;
            }
        }
        
        .gameIcon{
            width: 103px;
            height: 77px;
            position: relative;


            &.allbet{
                background: url(../images/index/btn01_allbet.jpg) center top no-repeat;
            }

            &.sunbet{
                background: url(../images/index/btn02_sunbet.jpg) center top no-repeat;
            }

            &.live{
                background: url(../images/index/btn03_live999.jpg) center top no-repeat;
                
                
            }

            &.ag{
                background: url(../images/index/btn04_ag.jpg) center top no-repeat;
            }

            &.vs{
                background: url(../images/index/btn05_vs.jpg) center top no-repeat;
            }

            &.allbet9{
                background: url(../images/index/btn06_allbet9.jpg) center top no-repeat;
            }

            &.n2{
                background: url(../images/index/btn07_n2live.jpg) center top no-repeat;
            }

            &.kg{
                background: url(../images/index/btn08_KG.jpg) center top no-repeat;
            }

            &.tpg{
                background: url(../images/index/dim_TPG.jpg) center top no-repeat;
            }

            &:hover{
                background-position: center bottom;
                cursor: pointer;
            }

            &.dim{
                filter:grayscale(100%);

                &:hover{
                    background-position: center top;
                    cursor: auto;
                }
            }
            
        }


        &:last-child {
            margin-right: 0;
        }
        .platform_logo {
            position: relative;
            display: block;
            height: 77px;
            overflow: hidden;
        }
        .new_stick {
            position: absolute;
            top: 0;
            right: 0;
        }
        &:hover .platform_logo {
            img {
                margin-top: -77px;
            }
            &.dim img {
                margin-top: 0;
            }
        }
        .platform_pop {
            position: absolute;
            top: 78px;
            left: -26px;
            background-image: url(../images/index/dropDown_with_promotion_bg.png);
            background-repeat: no-repeat;
            width: 800px;
            height: 200px;
            z-index: 9;
            display: block;
            opacity: 0;
            visibility: hidden;
            /*    transition: all .1s .5s;*/
            &.kg {
                left: -561px;
            }
            &.n2 {
                left: -456px;
            }
            &.ag {
                left: -136px;
            }
            &.live {
                left: -30px;
            }
        }
        &.active .platform_pop {
            display: block;
            opacity: 1;
            visibility: visible;
            transition: all .1s;
        }
        .platform_pop.noBg {
            background-image: url(../images/index/dropDown_without_promotion_bg.png);
            width: 390px;
            height: 200px;
        }
        .columnWrapper {
            display: block;
            padding: 20px 55px;
            box-sizing: border-box;
        }
        .column {
            display: inline-block;
            vertical-align: top;
            text-align: center;

            &.qrcode {
                padding: 0 45px;
            }
        }
        .platform_pop.noBg .column.qrcode {
            padding: 0 0 0 50px;
        }
        .column.thumbnail {
            padding: 8px 0px 5px 45px;
            border-left: 1px solid #da9f1d;
            box-sizing: border-box;
        }
        .col_title {
            display: block;
            padding-bottom: 10px;
        }
        .col_link a {
            display: block;
            background-image: url(../images/index/btn.png);
            background-repeat: no-repeat;
            background-position: center top;
            color: #cec66e;
            width: 112px;
            height: 24px;
            margin: 18px auto;
            padding: 2px 0;
            text-decoration: none;
            box-sizing: border-box;
            overflow: hidden;
            &:hover {
                background-position: center bottom;
            }
        }
        .icoLogin,
        .icoPlay {
            display: inline-block;
            vertical-align: middle;
        }
        .text {
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
        }
    }
}

#contact-title{
    background: rgb(70,5,3);
    background: -moz-linear-gradient(left, rgba(70,5,3,1) 0%, rgba(207,49,37,1) 51%, rgba(70,5,3,1) 100%);
    background: -webkit-linear-gradient(left, rgba(70,5,3,1) 0%,rgba(207,49,37,1) 51%,rgba(70,5,3,1) 100%);
    background: linear-gradient(to right, rgba(70,5,3,1) 0%,rgba(207,49,37,1) 51%,rgba(70,5,3,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#460503', endColorstr='#460503',GradientType=1 );

    width: 100%;
    height: 34px;
    float: left;
    text-align: center;
    font-size: 15px;
    line-height: 34px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #e8e251;
    margin: 0;
    text-shadow: 
    0 0 5px black, 
    0 0 5px black, 
    0 0 5px black, 
    0 0 5px black, 
    0 0 5px black;
}

#index_left {
    width: 186px;
    float: left;
    padding-top: 5px;
    height: 427px;

    #platform-one-percent {
        width: 186px;
        height: 192px;
        margin-top: 10px;
        position: relative;

        .regNow{
            width: 105px;
            height: 25px;
            display: block;
            margin: auto;
            border-radius: 5px;
            border: 1px solid #fff;
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 13px;
            line-height: 27px;
            font-size: 16px;
            font-weight: 700;
            color: #000;
            text-align: center;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dec688+1,f7f7f7+32,dec688+60,dec688+100 */
            background: #dec688; /* Old browsers */
            background: -moz-linear-gradient(top, #dec688 1%, #f7f7f7 32%, #dec688 60%, #dec688 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #dec688 1%,#f7f7f7 32%,#dec688 60%,#dec688 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #dec688 1%,#f7f7f7 32%,#dec688 60%,#dec688 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dec688', endColorstr='#dec688',GradientType=0 ); /* IE6-9 */
        }
    }


    #notice{
        height: 229px;
        border: 1px solid #897400;
        border-radius: 3px;

        h2{
            // background: url(../images/index/notice/index_notice_title.png) no-repeat;
            background: rgb(70,5,3);
            background: -moz-linear-gradient(left, rgba(70,5,3,1) 0%, rgba(207,49,37,1) 51%, rgba(70,5,3,1) 100%);
            background: -webkit-linear-gradient(left, rgba(70,5,3,1) 0%,rgba(207,49,37,1) 51%,rgba(70,5,3,1) 100%);
            background: linear-gradient(to right, rgba(70,5,3,1) 0%,rgba(207,49,37,1) 51%,rgba(70,5,3,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#460503', endColorstr='#460503',GradientType=1 );

            width: 100%;
            height: 34px;
            float: left;
            text-align: center;
            font-size: 14px;
            line-height: 34px;
            font-weight: bold;
            letter-spacing: 1px;
            color: #e8e251;
            margin: 0;

            text-shadow: 
            0 0 5px black, 
            0 0 5px black, 
            0 0 5px black, 
            0 0 5px black, 
            0 0 5px black
        }

        .contentWrap{
            // border: 1px solid #897400;
            // border-radius: 3px;

            marquee{
                width: 146px;
                height: 143px;
                text-align: justify;
                margin: 10px 20px;
                color: #ffde00;
                

                .newsWrapper{
                    padding: 20px 0px;
                    border-bottom: 2px solid #cfcfcf;
                    line-height: 1.4;

                    .date{
                        padding-bottom: 1px;
                    }
                }
            }

            .button{
                // background: url(../images/more.png) no-repeat;
                background: url(../images/index/common_btn.png) no-repeat;
                width: 74px;
                height: 26px;
                // text-indent: -9999px;
                float: right;
                margin: 0px 10px 3px;
                color: #efefef;
                line-height: 24px;
                font-size: 12px;
                text-align: center;
                position: relative;

                span{
                    position: relative;
                    z-index: 3;
                }

                &:hover{
                    // background-position: center bottom;
                    .button-hover-light{
                        opacity: 0.95;
                    }
                }
            }
        }
        
    }
}

.button-hover-light{
    width: 58px;
    height: 15px;
    opacity: 0;
    background:red;
    position: absolute;
    top:2px;
    // transition: all linear 0.1s;
    left:0px;
    right:0px;
    margin: auto;

    background: -moz-radial-gradient(center, ellipse cover,rgba(247, 243, 242, 0.4) 25%, rgba(20, 164, 191, 0.6) 38%, rgba(27, 128, 147, 0.31) 65%, rgba(251, 249, 249, 0) 100%);
    background: -webkit-radial-gradient(center, ellipse cover,rgba(247, 243, 242, 0.4) 25%, rgba(20, 164, 191, 0.6) 38%, rgba(27, 128, 147, 0.31) 65%, rgba(251, 249, 249, 0) 100%);
    background: radial-gradient(ellipse at center, rgba(247, 243, 242, 0.4) 25%, rgba(20, 164, 191, 0.6) 38%, rgba(27, 128, 147, 0.31) 65%, rgba(251, 249, 249, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66f7f3f2', endColorstr='#3b000000',GradientType=1 );
}

#index_right{
    width: 774px;
    float: left;
    padding: 5px 0 0 6px;
    box-sizing: border-box;

    #index_right{
        img{
            display: block;
        }
    }

    #index_services_box{
        .servicesBox{
            float: left;
            padding: 2px 1px 0;
            height: 323px;
            overflow: hidden;

            .title{
                display: block;
            }

            &.luckydraw .viewportWrap{
                .verticalSlide{
                    height: 231px;
                    overflow: hidden;
                    
                    a,img{
                        display: block!important;
                    }
                    
                    &.slick-vertical .slick-slide{
                        border: none;
                    }
                }
            }

            .viewportWrap{
                background: url(../images/index/services/index_description_bg1.png) no-repeat;
                width: 190px;
                height: 274px;
                position: relative;



                .imgSlider{
                    height: 151px;
                    img{
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        display: none;
    
                        &.first{
                            display: block;
                        }
                    }
                }

                .text{
                    padding: 12px 10px 0px;
                    font-size: 12px;
                    color: #fff;
                    text-align: justify;
                    line-height: 1.3;
                    position: relative;
                    z-index: 2;
                }

                .button{
                    // background: url(../images/index/services/index_description_button2.png) no-repeat;
                    background: url(../images/index/common_btn.png) center center no-repeat;
                    width: 71px;
                    height: 24px;
                    text-align: center;
                    margin: 6px auto;
                    cursor: pointer;
                    display: block;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    right: 0px;
                    color:#efefef;
                    line-height: 24px;
                    font-size:12px;

                    span{
                        position: relative;
                        z-index: 3;
                    }
                    
                    &:hover{
                        // @extend %hoverEffect;
                        .button-hover-light{
                            opacity: 0.95;
                        }
                    }

                    .button-hover-light{
                        width: 58px;
                        height: 15px;
                        opacity: 0;
                        background:red;
                        position: absolute;
                        top:2px;
                        // transition: all linear 0.1s;
                        left:0px;
                        right:0px;
                        margin: auto;

                        background: -moz-radial-gradient(center, ellipse cover,rgba(247, 243, 242, 0.4) 25%, rgba(20, 164, 191, 0.6) 38%, rgba(27, 128, 147, 0.31) 65%, rgba(251, 249, 249, 0) 100%);
                        background: -webkit-radial-gradient(center, ellipse cover,rgba(247, 243, 242, 0.4) 25%, rgba(20, 164, 191, 0.6) 38%, rgba(27, 128, 147, 0.31) 65%, rgba(251, 249, 249, 0) 100%);
                        background: radial-gradient(ellipse at center, rgba(247, 243, 242, 0.4) 25%, rgba(20, 164, 191, 0.6) 38%, rgba(27, 128, 147, 0.31) 65%, rgba(251, 249, 249, 0) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66f7f3f2', endColorstr='#3b000000',GradientType=1 );
                    }
                }
            }

            
        }
    }


}

#newsInfo{
    padding-top: 20px;
    #contactUs{
        float: left;
        width: 184px;
        border: 1px solid #897400;

        .title{
            img{
                display: block;
            }
        }
        .content{
            text-align: left;
            background: url(../images/index/contactUs/contactus_bg.png) center bottom no-repeat;
            padding: 44px 12px 7px 12px;
            height: 213px;
            position: relative;

            ul{
                li{
                    padding-bottom: 10px;
                    position: relative;

                    .contactPlatform{
                        color: #908545;
                    }
        
                    .info{
                        color: #fcefc3;
                    }

                    .icon{
                        display:inline-block;
                        vertical-align: middle;
                        width:27px;
                        height:23px;
                        text-align: center;
                        &.qqIcon{
                            background: url(../images/floating/icon_QQ.png) center center no-repeat;
                            background-size:contain;
                        }

                        &.wechatIcon{
                            background: url(../images/floating/icon_wc.png) center center no-repeat;
                            background-size:contain;
                            width:27px;
                            height:23px;
                        }
                    }

                    .details{
                        display: inline-block;
                        vertical-align: middle;
                    }

                    &:hover{
                        .wechat-qr-code-wrapper{
                            display:block;
                        }
                    }

                    .wechat-qr-code-wrapper{
                        position: absolute;
                        display:none;
                        top: -37px;
                        right: -53px;
                        z-index: 10;
                        width: 100px;
                        height: 100px;
        
                        img{
                            width:100%;
                            max-width: 100%;
                            height: 100%;
                        }
        
                        .arrow{
                            width: 15px;
                            height: 15px;
                            background: #FFF;
                            transform: rotate(45deg);
                            position: absolute;
                            top: 0px;
                            bottom: 0;
                            left: -7px;
                            margin: auto;
                            z-index: -1;
                        }
        
                        .wechat-qrcode{
                            height:100%;
                            position: relative;
                            z-index: 10;
                        }
                    }
                }
            }
            
        }
        .button{
            // background: url(../images/index/contactUs/index_contactus_button.png) no-repeat;
            background: url(../images/index/common_btn.png) center center no-repeat;
            width: 71px;
            height: 24px;
            display: inline-block;
            margin: 0;
            color: #efefef;
            line-height: 24px;
            font-size: 12px;
            text-align: center;
            position: relative;
            cursor: pointer;

            position: absolute;
            bottom: 10px;

            span{
                position: relative;
                z-index: 3;
            }

            &:hover{
                // @extend %hoverEffect;
                .button-hover-light{
                    opacity: 0.95;
                }
            }

            .button-hover-light{
                width: 58px;
                height: 15px;
                opacity: 0;
                background:red;
                position: absolute;
                top:2px;
                // transition: all linear 0.1s;
                left:0px;
                right:0px;
                margin: auto;

                background: -moz-radial-gradient(center, ellipse cover,rgba(247, 243, 242, 0.4) 25%, rgba(20, 164, 191, 0.6) 38%, rgba(27, 128, 147, 0.31) 65%, rgba(251, 249, 249, 0) 100%);
                background: -webkit-radial-gradient(center, ellipse cover,rgba(247, 243, 242, 0.4) 25%, rgba(20, 164, 191, 0.6) 38%, rgba(27, 128, 147, 0.31) 65%, rgba(251, 249, 249, 0) 100%);
                background: radial-gradient(ellipse at center, rgba(247, 243, 242, 0.4) 25%, rgba(20, 164, 191, 0.6) 38%, rgba(27, 128, 147, 0.31) 65%, rgba(251, 249, 249, 0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66f7f3f2', endColorstr='#3b000000',GradientType=1 );
            }
        }

    }




    #news{
        .news_wrapper{
            float: left;
            width: 767px;
            height: 266px;
            margin-left: 6px;
            overflow: hidden;
            position: relative;

            //- default bg same as news1 bg
            background: url(../images/index/news/news-bg01.jpg) no-repeat;
            
            .tabList{
                padding-top: 21px;
                position: absolute;
                right: 0px;
                z-index: 10;

                li {
                    float: left;
                    height: 45px;
                    // text-indent: -9999px;
                    cursor: pointer;
                    width: 132px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: bold;
                    color: #fff265;
                    text-shadow: 0 0 5px black, 0 0 5px black;
                    line-height: 45px;
                    margin-left: -20px;
                    padding-left: 10px;
                    box-sizing: border-box;

                    &.tabs{
                        background: url(../images/index/news/red_tab.png) no-repeat;
                    }

                    // &.tab1 {
                    //     width: 124px;
                    //     background: url(../images/index/news/news-btn01.jpg) no-repeat;
                    // }
                    
                    // &.tab2 {
                    //     background: url(../images/index/news/news-btn02.jpg) no-repeat;
                    // }
                    
                    // &.tab3 {
                    //     background: url(../images/index/news/news-btn03.jpg) no-repeat;
                    // }
                    
                    // &.tab4 {
                    //     background: url(../images/index/news/news-btn04.jpg) no-repeat;
                    // }

                    &.active,&:hover{
                        // background-position: center bottom;
                        background: url(../images/index/news/red_tab_hover.png) no-repeat;
                    }
                }
                

            }

            .news{
                width: 100%;
                height: 100%;
                display: none;
                position: absolute;
                left: 0px;
                top: 0px;

                // &.active{
                //     display: block;
                // }

                &.news1{
                    background: url(../images/index/news/news-bg01.jpg) no-repeat;
                }

                &.news2{
                    background: url(../images/index/news/news-bg02.jpg) no-repeat;
                }

                &.news3{
                    background: url(../images/index/news/news-bg03.jpg) no-repeat;
                }

                &.news4{
                    background: url(../images/index/news/news-bg04.jpg) no-repeat;
                }

                ul{
                    @extend %clearfix;
                    width: 476px;
                    padding-top: 110px;
                    padding-left: 30px;
                    li{
                        @extend %bulletList;
                        padding: 3px 0 5px 18px;
                        width: 220px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: white;
                    }
                }
                .button{
                    display: block;
                    text-indent: -9999px;
                    width: 44px;
                    height: 24px;
                    background: url(../images/news-btn-more.jpg) no-repeat;
                    margin: 2px 0 0 455px;

                    &:hover{
                        @extend %hoverEffect;
                    }
                }
            }
        }
    }
}

#brand{
    clear: both;
    padding-top: 20px;
    .tabList li {
        width: 228px;
        height: 30px;
        // text-indent: -9999px;
        float: left;
        padding-right: 15px;
        cursor: pointer;
        display: block;
        text-align: center;
        color:#8e8e8e;
        font-size: 14px;
        text-shadow: 0 0 5px #8e8e8e;
        line-height: 30px;

        &.active{
            color:#FFF;
            text-shadow: 0 0 5px #ffdcdb, 0 0 5px #9c27b0;
            background-position: bottom left !important;
        }

        &:last-child{
            padding-right: 0px;
        }

        background: url(../images/index/luxury/index_luxury_tab.png) no-repeat;
        background-position-y: 0px;

        // &.fashion{
        //     background: url(../images/index/luxury/index_luxury_fashion_tab.png) no-repeat;
        // }

        // &.watch{
        //     background: url(../images/index/luxury/index_luxury_watch_tab.png) no-repeat;
        // }

        // &.car{
        //     background: url(../images/index/luxury/index_luxury_car_tab.png) no-repeat;
        // }

        // &.electronic{
        //     background: url(../images/index/luxury/index_luxury_electronic_tab.png) no-repeat;
        //     padding-right: 0;
        // }
    }

    .content{
        overflow: hidden;
        height: 186px;
        width: 100%;
        .category{
            
            // display: none;

            &.active{
                display: block;
            }

            .page{
                height: 184px;
                overflow: hidden;
                ul{
                    li{
                        float: left;
                        a,img{
                            display: block;
                        }

                        a{
                            position: relative;

                            &:hover{
                                img.hoverImg{
                                    display: block!important;
                                }
                            }
                        }

                        img.hoverImg{
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            display: none;
                            z-index: 2;
                        }
                    }
                }
            }

            .slick-prev{
                left: 0px;
                background: url(../images/index/luxury/index_luxury_arrow_left.png) center top no-repeat;
                z-index: 2;
            }

            .slick-next{
                right: 0px;
                background: url(../images/index/luxury/index_luxury_arrow_right.png) center top no-repeat;
                z-index: 2;
            }

            .slick-prev,
            .slick-next{
                width: 17px;
                height: 185px;
                z-index: 3;
                top: 50%;
                display: none!important;

                &:before{
                    display: none;
                }
            }

            &:hover{
                .slick-prev,
                .slick-next{
                    display: block!important;
                }
            }
        }

        .contentList {
    
            li{
                float: left;
                width: 192px;
                position: relative;
    
                a,img{
                    display: block;
                }


            }
        }
    }

    

}


#indexFormWrapper{
    max-width: 620px;
    width: 100%;
    margin: auto;
    display: none;

    .formRow{
        margin: 10px 0px;    
        display: table;
        width: 100%;
        a{
            display: table-cell;
            vertical-align: middle;

            img{
                display: block;
                width: 100%;
            }
        }

        &.col3{
            a{
                padding: 0px 5px;
            }
        }
    }
}

#footer{
    .footerWrap{
        text-align: center;
        padding: 25px 10%;
        box-sizing: border-box;

        @media(max-width:480px){
           padding: 5% 10% 25% 10%;
        }
        
        a, p{
            font-size: 12px;
            color: #C5C08C;
        }

        a{
            padding: 0px 5px;
            border-left: 1px solid #C5C08C;
        }

        p{
            padding-bottom: 10px;
            line-height: 1.3;
        }

        .partnerImg{
            padding: 0px 0px 10px;
            width: 100%;
            max-width: 425px;
        }

        .copyright span{
            padding-right: 5px;
        }
    }
}

#content-wrapper.inex-popup-banner{

    &.modal-hide{
        display:none;
    } 

    position: relative;
    border: 2px solid rgb(114, 0, 24);
    z-index: 1500;
    border-radius: 4px;
    background-color: rgb(114, 0, 24);
    background-image: -moz-linear-gradient( -90deg, rgb(83, 0, 8) 0%, rgb(95, 0, 20) 100%);
    background-image: -webkit-linear-gradient( -90deg, rgb(83, 0, 8) 0%, rgb(95, 0, 20) 100%);
    background-image: -ms-linear-gradient( -90deg, rgb(83, 0, 8) 0%, rgb(95, 0, 20) 100%);
    width: 700px;
    padding: 0 20px 20px 20px;
    // left: calc((100vw - 700px)/2);
    // top: calc((100vh - 500px)/2);
    left: 0px;
    margin: auto auto 2% auto;
    right: 0px;
    top:5%;
    position: fixed;
   

    #promotion-scroll-wrapper{
        max-height: 75vh;
        overflow-y: auto;
        margin-bottom: 15px !important;
    }

    @media(max-width:960px){
        // top:20%;
        top:5%;
        padding: 1%;
        box-sizing: border-box;
    }

    @media(max-width:689px){
        width:95%;
        left: 0px;
        margin: auto;
        right: 0px;
    }

    .popup-close{
        cursor: pointer;
        background-color: rgb(134, 0, 28);
        width: 35px;
        height: 35px;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 18px;
        text-align: center;
        color: #fff;
        line-height: 35px;
    }

    .popup-title{
        margin: 15px 0;

        @media(max-width:769px){
            width:100%;
        }
    }

    .promotion{

        @media(max-width:768px){
            img{
                width:100%;
            }
        }

        img{
            float:left;
            max-width: 100%;
        }

        display: inline-block;
        position: relative;
        font-size: 18px;
        color: #fff;
        line-height: 35px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 15px;
        overflow: hidden;

        .promotion-tag{
            background-color: rgb(143, 106, 32);
            // width: 131px;
            padding:0px 10px;
            height: 35px;
            position: absolute;
            left: 0;
            z-index: 999;
            top: 0;

            @media(max-width:480px){
                font-size:13px;
                padding: 0% 1%;
                width:auto;
                height: auto;
            }
        }

        .promotion-hover{
            // display: none;
            opacity: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: rgba(29, 17, 25, 0.7);
            position: absolute;
            left: 0;
            top: 0;
            a{
                text-decoration: none;
            }

            .promo-login{
                color: #000000;
                display: inline-block;
                margin: 0 20px;
                margin-top: 84px;
                line-height: 48px;
                letter-spacing: 2px;
                border-style: solid;
                border-width: 1px;
                border-color: rgb(255, 255, 255);
                border-radius: 4px;
                background-color: #fff1bb;
                background-image: -moz-linear-gradient( 90deg, rgb(255, 235, 157) 0%, rgb(255, 255, 255) 100%);
                background-image: -webkit-linear-gradient( 90deg, rgb(255, 235, 157) 0%, rgb(255, 255, 255) 100%);
                background-image: -ms-linear-gradient( 90deg, rgb(255, 235, 157) 0%, rgb(255, 255, 255) 100%);
                width: 248px;
                height: 48px;
            }

            .promo-view{
                border-style: solid;
                border-width: 1px;
                border-color: rgb(255, 251, 236);
                border-radius: 4px;
                background-color: #fff;
                background-image: -moz-linear-gradient( -90deg, rgb(255, 255, 255) 0%, rgb(185, 182, 196) 100%);
                background-image: -webkit-linear-gradient( -90deg, rgb(255, 255, 255) 0%, rgb(185, 182, 196) 100%);
                background-image: -ms-linear-gradient( -90deg, rgb(255, 255, 255) 0%, rgb(185, 182, 196) 100%);
                width: 249px;
                height: 48px;
                margin: 0 20px;
                margin-top: 84px;
                color: #000000;
                display: inline-block;
                letter-spacing: 2px;
                line-height: 48px;
            } 
        }

        &:hover{
           .promotion-hover{
                opacity: 1;
                z-index: 2;

           } 
        }
    }

    .subscribe{
        .leftText{
            display: inline-block;
            vertical-align: middle;
            width: 34%;

            h3{
                font-size: 18px;
                margin: 5px 0;
                color: #fff2b6;

                @media(max-width:769px){
                    font-size:16px;
                }

                @media(max-width:645px){
                    font-size:14px;
                }
            }

            h4{
                font-size: 13px;
                margin: 0;
                font-weight: bold;
                color: #e6e6e6;

                @media(max-width:769px){
                    font-size:14px;
                }

                @media(max-width:645px){
                    font-size:12px;
                }
            }

            @media(max-width:768px){
                width:39%;
            }

            @media(max-width:530px){
                width:100%;
                margin-bottom: 3%;
            }
        }

        input[type="text"]{
            display: inline-block;
            vertical-align: middle;
            width: 41%;
            margin-right: 10px;
            color: #585151;
            padding: 9px 9px;

            @media(max-width:768px){
                width:31%;
            }

            @media(max-width:530px){
                width:70%;
                margin-right: 0px;
                box-sizing: border-box;
                float: left;
            }
        }

        input[type="submit"]{
            display: inline-block;
            vertical-align: middle;
            width: 124px;
            height: 35px;
            cursor: pointer;
            // background-image: url(../images/popup/btn.png);
            background: rgb(178,131,23);
            background: -moz-linear-gradient(top, rgba(178,131,23,1) 0%, rgba(127,93,0,1) 41%, rgba(182,153,71,1) 100%);
            background: -webkit-linear-gradient(top, rgba(178,131,23,1) 0%,rgba(127,93,0,1) 41%,rgba(182,153,71,1) 100%);
            background: linear-gradient(to bottom, rgba(178,131,23,1) 0%,rgba(127,93,0,1) 41%,rgba(182,153,71,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b28317', endColorstr='#b69947',GradientType=0 );
            border:1px solid #fff203;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            padding: 0;
            color: #fff;
            font-size: 18px;
            border-radius: 1px;
            &:hover{
                // background-position: 0 100%;
                border:1px solid #FFF;
                background: rgb(238,211,87);
                background: -moz-linear-gradient(top, rgba(238,211,87,1) 0%, rgba(115,94,0,1) 100%);
                background: -webkit-linear-gradient(top, rgba(238,211,87,1) 0%,rgba(115,94,0,1) 100%);
                background: linear-gradient(to bottom, rgba(238,211,87,1) 0%,rgba(115,94,0,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eed357', endColorstr='#735e00',GradientType=0 );
            }

            @media(max-width:768px){
                width:20%;
            }

            @media(max-width:530px){
                width:29%;
                box-sizing: border-box;
                float: right;
            }
        }
    }
}

// .modal-overlay{
//     position: fixed;
//     top:0px;
//     left:0px;
//     width:100%;
//     height:100%;
//     background:rgba(0,0,0,0.5);
//     z-index: 100;
// }