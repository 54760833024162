#InviteWindow{
	visibility: visible;
	top: 336px; 
	left: 35%; 
	cursor: move;
    text-align: left;
    position: fixed;
    z-index: 8889;
    font-size: 13px;
    width: 435px;
    height: 157px;

    @media(max-width:769px){
    	visibility: hidden;
    }

    &.modal-hide{
    	visibility: hidden;
    }

    #invite_bg{
	    background-image: url(../images/invite/invitation_box_bg_cn_2.png) !important;
    	height: 157px !important;
    	width: 437px !important;
	    position: absolute;
    	z-index: 1;
    }

    #invite_title {
	    display: none;
	    cursor: move;
	}

	#invite_text {
	    line-height: 14px;
	    padding: 35px 15px 0 120px;
	    height: 80px;
	    overflow: hidden;
	    position: absolute;
	    z-index: 2;
	    color: #e4ede8;
	}

	#invite_btn{
	    position: relative;
	    padding: 0;
	    top: 0;
	    z-index: 2;
	    left: 0;
	    width: 100%;

		button{
	        text-indent: -9999px;
		    border: none;
		    margin: 0 0 0 10px;
		    display:block;
		    float:left;
			&.accept{
			    background: url(../images/invite/btn.png) no-repeat !important;
			    width: 147px !important;
			    height: 44px !important;
			    position: absolute !important;
			    left: 180px !important;
			    top: 110px !important;
			    outline: none;
			    cursor: pointer;

			    &:hover{
			    	background-position: left bottom !important;
			    }
			}

			&.cancel{
			    background: url(../images/invite/close.png) no-repeat !important;
			    width: 14px !important;
			    height: 12px !important;
			    position: absolute !important;
			    top: 8px !important;
			    right: 8px !important;
			    outline: none;
			    cursor: pointer;
			}
		}
	}
}

.modal-content{
	position: relative;
	width:100%;
}