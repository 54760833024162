$mobileSreen : 600px;

#live51{
	position: fixed;
    right: 23px;
    top: 215px;
    /* top: 415px; */
    -webkit-filter: drop-shadow( -5px -5px 10px #310200 );
    filter: drop-shadow( -5px -5px 10px #310200 );
    _position: absolute !important;
    _bottom: auto;
    _top: expression(eval(document.compatMode && document.compatMode=='CSS1Compat') ? documentElement.scrollTop+195: document.body.scrollTop + (document.body.clientHeight -this.clientHeight)) !important;
    z-index: 1000;

    @media(max-width:$mobileSreen){
    	display:none;
    }

    &.hide{
    	display:none;
    }
}

#qqBtn{
    position: fixed;
	top: 294px;
    /* top: 526px; */
    right: 24px;
    // background-image: url(../images/floating/floating_board.png);
    background-repeat: no-repeat;
    width: 180px;
    font-size: 13px;
    color: #fff;
	padding: 4px 0px 0px 22px;
    box-sizing: border-box;
    text-decoration: none;
    z-index: 1000;
    filter: drop-shadow( -5px 5px 10px #310200 );
    -webkit-filter: drop-shadow( -5px 5px 10px #310200 );

	.contact-info-row{
		background: url(../images/floating/floating_cs_bg.png) center center no-repeat;
		background-size:contain;
		box-sizing: border-box;
		padding: 4px 5px;
		height: 40px;
		margin-bottom: 3px;
		position: relative;

		.text{
			font-size:15px;
			font-family: Arial, Helvetica, sans-serif;
		}

		&.wechat{
			&:hover{
				.wechat-qr-code-wrapper{
					display:block;
				}
			}

			.wechat-qr-code-wrapper{
				position: absolute;
				display:none;
				left: -120px;
				top: -33px;
				width: 100px;
				height: 100px;

				img{
					width:100%;
					max-width: 100%;
					height: 100%;
				}

				.arrow{
					width: 15px;
					height: 15px;
					background: #FFF;
					transform: rotate(45deg);
					position: absolute;
					top: 0px;
					bottom: 0;
					right: -7px;
					margin: auto;
					z-index: -1;

				}

				.wechat-qrcode{
					height:100%
				}
			}
		}

		.Icon{
			width:27px;
			height: 21px;
			text-align:center;
			display:inline-block;
			vertical-align: middle;
			margin-right: 2px;

			&.qqIco{
				background: url(../images/floating/icon_QQ.png) center center no-repeat;
				background-size:contain;
			}

			&.wechatIco{
				background: url(../images/floating/icon_wc.png) center center no-repeat;
				background-size:contain;
				width:27px;
				height: 21px;
			}
		}

		.status-light{
			float:right;
			margin-top: 5px;
			margin-right: 2px;
		}

		
	}


    a{
    	display: block;
	    color: #fff;
        height: 13px;
		// margin: 2px auto;
		margin:5px auto;
    	&:hover{
		    color: #cec66e;
		    text-decoration: underline;
    	}
    }

    &.hide{
    	display:none;
    }

    @media(max-width:650px){
    	display:none;
    }
}

#live-close{
    top: 207px;
    right: 10px;
    &:hover{
    	background-position: bottom center;
    }

    @media(max-width:$mobileSreen){
    	display:none;
    }

    &.hide{
    	display:none;
    }
}

.close-btn{
    background: url(../images/floating/close-btn.png);
    background-position: top center;
    position: fixed;
    cursor: pointer;
    z-index: 1001;
    width: 23px;
    height: 23px;
}

#agentBox{
    position: fixed;
    right: 20px;
    top: 485px;
    /* top: 600px; */
    cursor: pointer;
    -webkit-filter: drop-shadow( -5px -5px 10px #310200 );
    filter: drop-shadow( -5px -5px 10px #310200 );
    _position: absolute !important;
    _bottom: auto;
    _top: expression(eval(document.compatMode && document.compatMode=='CSS1Compat') ? documentElement.scrollTop+295: document.body.scrollTop + (document.body.clientHeight -this.clientHeight)) !important;
	z-index: 1000;
	
	background:url("../images/floating/agent-floating6.jpg");
	width: 162px;
	height: 113px;
	box-sizing: border-box;
	padding: 50px 10px;
	
	.agentbox-row{
		color: #831508;
		font-weight: bold;
		font-size: 15px;
		margin: 7px 0px;

		text-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #f4c559 0px 0px 30px, #f4c559 0px 0px 40px, #f4c559 0px 0px 50px, #f4c559 0px 0px 75px, 2px 2px 2px rgba(245,238,146,0);
		color: #831508;
	}

	
    @media(max-width:$mobileSreen){
    	display:none;
    }

    &.hide{
    	display:none;
    }
}

#jackpot-popup-close{
    top: 206px;
    left: 182px;

    @media(max-width:$mobileSreen){
    	display:none;
    }

    &.hide{
    	display:none;
    }
}

#jackpot-popup1{
	z-index: 20;
    position: fixed;
    left: 10px;
	top: 220px;
    .jackpot-popup{
	    height: 119px;
	    width: 192px;
	    text-indent: -9999px;
	    display: block;
        background-image: url(../images/floating/small_final_b4.gif);
	    background-position: top left;
	    background-repeat: no-repeat;
    }
}

#jackpot-popup2{
	z-index: 20;
    position: fixed;
    left: 10px;
	top: 342px;
    .jackpot-popup{
	    height: 119px;
	    width: 192px;
	    text-indent: -9999px;
	    display: block;
	        background-image: url(../images/floating/jackpot_congra_s2c.gif);
		    background-position: top left;
		    background-repeat: no-repeat;
    }
}

#jackpot-popup3{
	z-index: 20;
    position: fixed;
    left: 10px;
    top: 455px;
    .jackpot-popup{
	    height: 119px;
	    width: 192px;
	    text-indent: -9999px;
	    display: block;
	    background-image: url(../images/floating/jackpot_congra_s2b2.gif);
	    background-position: top left;
	    background-repeat: no-repeat;
    }
}

.jackpot-box,
#jackpot-popup-close{
	@media(max-width:1300px){
    	display:none;
    }

    @media(max-width:480px){
    	display:none;
    }

    &.hide{
    	display:none;
    }
}

#floating-right{
    position: fixed;
    display: none;
    right: 0px;
    top: 220px;
    width: 52px;
    // height: 497px;
    cursor: pointer;
    z-index: 999;

    @media(max-width:$mobileSreen){
    	display:none!important;
    }

    // @media(max-width:480px){
    // 	display:none;
    // }

    &.active{
    	display:block;
    }
}


/** mobile bottom nav bar **/
#bottomNav{
	@media(max-width:960px){
		display:block;
	}

	display:none;
    width: 100%;
    background: url(../images/mobile/bottom_bg.jpg);
    background-size: 320px 65px;
    background-repeat: repeat;
    height: 65px;
    position: fixed;
    z-index: 999;
    bottom: 0;

	.iconWrapper {
	    max-width: 640px;
	    margin: auto;

	    .iconItm{
	    	position: relative;
	    }

	    .icon{
    	    width: 25%;
		    height: 55px;
		    margin-top: 5px;
		    display: inline-block;
		    max-width: 160px;
		    float: left;
		    background-position: center center;
		    background-size: contain;
		    background-repeat: no-repeat;

		    .btnLink{
	    	    display: block;
			    width: 100%;
			    height: 100%;
		    }

		    &.cs{
		    	background-image: url(../images/mobile/bottom-cs_btn.png);
		    }

		    &.agent{
		    	background-image: url(../images/mobile/bottom-agent-reg_btn.png);
		    }

		    &.platform{
		    	background-image: url(../images/mobile/bottom-platform_btn.png);
		    }

		    &.common{
		    	background-image: url(../images/mobile/bottom-common_btn.png);
		    }
	    }
	}

	.popupBottom{
		&.show{
			display:block;
		}

	    position: absolute;
	    top: -195px;
	    right: 4%;
	    background-image: url(../images/mobile/popup_bg.png);
	    background-repeat: no-repeat;
	    background-size: 252px auto;
	    width: 253px;
	    height: 194px;
	    padding: 5px;
	    box-sizing: border-box;
	    display: none;

	    .popupIcon{
	    	display: inline-block;
		    vertical-align: top;
		    width: 33.3333%;
		    padding: 8px 10px;
		    box-sizing: border-box;
		    img{
	    	    display: block;
    			width: 62px;
		    }
	    }
	}
}